<template>
    <div class="meetings-page">
        <!--线上会议  发起/预约会议 -->
        <div class="meeting_top">
            <div
                class="meeting_all"
                v-if="isShowOnlineAll"
                @click="toAllOnline"
            >
                <img
                    class="meeting_img"
                    :src="allImg"
                    alt="all"
                />
                <div class="meeting_title">所有会议</div>
                <div class="meeting_num">{{ meetingAllNum }}</div>
            </div>
            <div
                class="meeting_will"
                v-if="isShowWillAttend"
                @click="toWillAttend"
            >
                <img
                    class="meeting_img"
                    :src="willImg"
                    alt="all"
                />
                <div class="meeting_title">待我参会</div>
                <div class="meeting_num">{{ meetingNoAttendedNum }}</div>
            </div>
            <div
                class="meeting_done"
                v-if="isShowAttended"
                @click="toAttended"
            >
                <img
                    class="meeting_img"
                    :src="doneImg"
                    alt="all"
                />
                <div class="meeting_title">我已参会</div>
                <div class="meeting_num">{{ meetingAttendedNum }}</div>
            </div>
            <div
                class="meeting_mine"
                v-if="isShowOnlineLaunch"
                @click="toOnlineLaunch"
            >
                <img
                    class="meeting_img"
                    :src="mineImg"
                    alt="all"
                />
                <div class="meeting_title">我发起的</div>
                <div class="meeting_num">{{ meetingLaunchNum }}</div>
            </div>
            <el-tooltip
                class="item"
                effect="dark"
                content="因会议为钉钉会议，使用的前提为智慧校园成功对接了钉钉，并且电脑端下载并安装好钉钉软件！"
                placement="top-start"
            >
                <div class="meeting_info">
                    <img
                        class="meeting_img"
                        :src="tipImg"
                        alt="tip"
                    />
                    <div class="meeting_title">
                        因会议为钉钉会议，使用的前提为智慧校园成功对接了钉钉，并且电脑端下载并安装好钉钉软件！
                    </div>
                </div>
            </el-tooltip>
        </div>
        <div class="meeting-wrap-content">
            <div class="meeting-cell meeting-launch">
                <div class="meeting_mobile_bg">
                    <div class="meeting_launch_title">发起会议</div>
                    <div
                        v-if="isShowGoWillBtn"
                        class="meeting-mobile-wrapper"
                    >
                        <div
                            class="meeting-mobile-btn"
                            @click="meetingLaunchClick"
                        >
                            <img
                                class="gowillpng"
                                src="../../../assets/images/meeting/gowill_active.png"
                                alt="发起会议"
                            />
                        </div>
                        <div
                            class="gowilltext"
                            @click="meetingLaunchClick"
                        >
                            去发起
                            <img
                                class="gowillright"
                                :src="rightImg"
                                alt="rightImg"
                            />
                        </div>
                    </div>
                    <div
                        v-else
                        class="meeting-mobile-wrapper"
                    >
                        <div class="meeting-mobile-btn-info">
                            <img
                                class="gowillpnginfo"
                                src="../../../assets/images/meeting/gowill_info.png"
                                alt="发起会议"
                            />
                        </div>
                        <div class="gowilltext_info">去发起</div>
                    </div>
                </div>
                <div class="meeting_mobile_tip">
                    <div class="tip_txt">说明：</div>
                    <div class="texts">
                        <p class="meeting-tips-row">线上会议支持钉钉会议，可以实时发起会议，也可以提前预约会议</p>
                        <p class="meeting-tips-row">
                            并通过钉钉自动提醒指定的参会人员按时入会
                        </p>
                        <p class="meeting-tips-row">
                            同时支持自动形成会议纪要、录屏回放，以便于后期查询和回顾会议内容
                        </p>
                    </div>
                    <div class="gowillplane">
                        <img
                            class="plane_icon"
                            :src="planeImg"
                            alt="planeImg"
                        />
                    </div>
                </div>
            </div>
            <div class="meeting-cell meeting-appointment">
                <div class="meeting_mobile_bg">
                    <div class="meeting_launch_title">预约会议</div>
                    <div
                        v-if="isShowBeforeBtn"
                        class="meeting-mobile-wrapper"
                    >
                        <div
                            class="meeting-mobile-btn"
                            @click="meetingAppointmentClick"
                        >
                            <img
                                class="gowillpng"
                                src="../../../assets/images/meeting/before_active.png"
                                alt="预约会议"
                            />
                        </div>
                        <div
                            class="gowilltext"
                            @click="meetingAppointmentClick"
                        >
                            去预约
                            <img
                                class="gowillright"
                                :src="rightImg"
                                alt="rightImg"
                            />
                        </div>
                    </div>
                    <div
                        v-else
                        class="meeting-mobile-wrapper"
                    >
                        <div class="meeting-mobile-btn-info">
                            <img
                                class="gowillpnginfo"
                                src="../../../assets/images/meeting/gowill_info.png"
                                alt="发起会议"
                            />
                        </div>
                        <div class="gowilltext_info">去预约</div>
                    </div>
                </div>
                <div class="meeting_mobile_tip">
                    <div class="tip_txt">说明：</div>
                    <div class="texts">
                        <p class="meeting-tips-row">可以实现线上会议预约</p>
                        <p class="meeting-tips-row">
                            会议开始前钉钉发送参会提醒通知参会人按时参会
                        </p>
                        <p class="meeting-tips-row">
                            会议开始时系统可自动发起钉钉会议并邀请参会人
                        </p>
                    </div>
                    <div class="gowilltime">
                        <img
                            class="time_icon"
                            :src="timeImg"
                            alt="timeImg"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- 发起会议 -->
        <dialog-wrapper
            :dialogObj="meetingLaunchData"
            @handleClose="meetingLaunchClose"
            class="meetings-dialogs"
        >
            <el-form
                class="common-form"
                :model="meetingLaunchForm"
                :rules="meetingLaunchRules"
                label-width="100px"
                ref="meetingLaunchForm"
            >
                <el-form-item
                    label="发起人"
                    prop="meetingPeople"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="meetingLaunchForm.meetingPeople"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="标题"
                    prop="meetingTtitle"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="meetingLaunchForm.meetingTtitle"
                        clearable
                        placeholder="请输入标题"
                        maxlength="20"
                        show-word-limit
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="类型"
                    prop="meetingType"
                    class="metting-select"
                >
                    <el-select
                        class="input-width-medium"
                        v-model="meetingLaunchForm.meetingType"
                        filterable
                        clearable
                        placeholder="请选择类型"
                    >
                        <el-option
                            v-for="item in meetingLaunchForm.meetingTypeOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                    <div class="spanTip">
                        <img class="tipImg" src="@/assets/images/tip1.png">
                        <div>不同类型的会议，录屏文件保存期限不同</div>
                    </div>
                </el-form-item>

                <el-form-item
                    label="科目"
                    prop="subject"
                    class="metting-select"
                >
                    <el-select
                        class="input-width-medium"
                        v-model="meetingLaunchForm.subject"
                        filterable
                        clearable
                        placeholder="请选择科目"
                        @change="getLSubjectName"
                    >
                        <el-option
                            v-for="item in meetingLaunchForm.subjectOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="年级"
                    prop="grade"
                    class="metting-select"
                >
                    <el-cascader
                        ref="cascaderLaunchGradeRef"
                        class="input-width-medium"
                        v-model="meetingLaunchForm.grade"
                        :props="{
                            label: 'name',
                            multiple: false,
                            value: 'id',
                            children: 'child',
                            checkStrictly: true,
                            emitPath:false
                        }"
                        :options="meetingLaunchForm.gradeOptions"
                        collapse-tags
                        :placeholder="'请选择年级'"
                        :clearable="true"
                        filterable
                        @change="handleLaunchGradeChange"
                    ></el-cascader>
                    <!-- <el-select
                        class="input-width-medium"
                        v-model="meetingLaunchForm.grade"
                        filterable
                        clearable
                        placeholder="请选择年级"
                        @change="getLGradeName"
                    >
                        <el-option
                            v-for="item in meetingLaunchForm.gradeOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select> -->

                </el-form-item>
                <el-form-item
                    label="参会人"
                    prop="meetingParticipant"
                    class="join-meeting"
                >
                    <div class="meeting-participant">
                        <div
                            class="meeting-participant-cell meeting-participant-add_button"
                            @click="addPeopleClick"
                        >
                            <i class="el-icon-plus"></i>
                            <span>添加参会人</span>
                        </div>
                        <div
                            class="meeting-participant-cell meeting-participant-people"
                            v-for="item in meetingLaunchForm.meetingParticipant"
                            :key="item.id"
                        >
                            <div class="meeting-participant-people-icon">
                                <img
                                    v-if="item.avatar"
                                    :src="item.avatar"
                                    alt=""
                                    title=""
                                />
                                <div
                                    v-else
                                    class="meeting-participant-people-icon-text"
                                >
                                    <span v-if="item.teacherName">{{
                                        item.teacherName.substring(0, 1)
                                    }}</span>
                                </div>
                            </div>
                            <div class="meeting-participant-people-wrap">
                                <div class="meeting-participant-people-name">
                                    {{ item.teacherName }}
                                </div>
                                <div
                                    class="meeting-participant-people-close"
                                    @click="
                                        meetingLaunchSelectedPeopleRemoveClick(
                                            item,
                                        )
                                    "
                                >
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    label="录屏/闪记"
                    prop="screenRecording"
                >
                    <el-switch
                        v-model="meetingLaunchForm.screenRecording"
                        active-value="1"
                        inactive-value="0"
                        active-color="#3C7FFF"
                        inactive-color="#dcdfe6"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <div class="meeting-form-button flex_end">
                <el-button
                    type="primary"
                    @click="meetingLaunchConfirm('meetingLaunchForm')"
                    :disabled="disabledState"
                    >发起会议</el-button
                >
            </div>
        </dialog-wrapper>
        <!-- 预约会议 -->
        <dialog-wrapper
            :dialogObj="meetingAppointmentData"
            @handleClose="meetingAppointmentClose"
            class="meetings-dialogs"
        >
            <el-form
                class="common-form"
                :model="meetingAppointmentForm"
                :rules="meetingAppointmentRules"
                label-width="100px"
                ref="meetingAppointmentForm"
                v-loading="createdLoading"
                element-loading-text="由于目前生成会议的数量较多，可能需要一些时间来完成，请您耐心等待，我们将尽快处理！"
            >
                <el-form-item
                    label="发起人"
                    prop="meetingPeople"
                    style="width: 452px"
                >
                    <div class="meeting-appointment-participant">
                        <div
                            class="meeting-participant-cell meeting-participant-add_button"
                            @click="addAppointmentPeopleClick"
                        >
                            <i class="el-icon-plus"></i>
                            <span>选择发起人</span>
                        </div>
                        <div
                            class="meeting-participant-cell meeting-participant-people"
                            v-for="item in meetingAppointmentForm.meetingPeople"
                            :key="item.id"
                        >
                            <div class="meeting-participant-people-icon">
                                <img
                                    v-if="item.avatar"
                                    :src="item.avatar"
                                    alt=""
                                    title=""
                                />
                                <div
                                    v-else
                                    class="meeting-participant-people-icon-text"
                                >
                                    <span v-if="item.teacherName">{{
                                        item.teacherName.substring(0, 1)
                                    }}</span>
                                </div>
                            </div>
                            <div class="meeting-participant-people-wrap">
                                <div class="meeting-participant-people-name">
                                    {{ item.teacherName }}
                                </div>
                                <div
                                    class="meeting-participant-people-close"
                                    @click="
                                        meetingALSelectedPeopleRemoveClick(item)
                                    "
                                >
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    label="标题"
                    prop="meetingTtitle"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.meetingTtitle"
                        clearable
                        placeholder="请输入标题"
                        maxlength="20"
                        show-word-limit
                    ></el-input>
                    <div class="spanTip">周期会议标题自动命名规范：【当前标题】+【会议日期】，如：“早会20240919”</div>
                </el-form-item>
                <el-form-item
                    label="类型"
                    prop="meetingType"
                    class="metting-select"
                >
                    <el-select
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.meetingType"
                        filterable
                        clearable
                        placeholder="请选择类型"
                    >
                        <el-option
                            v-for="item in meetingAppointmentForm.meetingTypeOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                    <div class="spanTip">
                        <img class="tipImg" src="@/assets/images/tip1.png">
                        <div>不同类型的会议，录屏文件保存期限不同</div>
                    </div>
                    <!-- <div class="spanTip">不同类型的会议，录屏文件保存期限不同</div> -->
                </el-form-item>

                <el-form-item
                    label="科目"
                    prop="subject"
                    class="metting-select"
                >
                    <el-select
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.subject"
                        filterable
                        clearable
                        placeholder="请选择科目"
                        @change="getYSubjectName"
                    >
                        <el-option
                            v-for="item in meetingAppointmentForm.subjectOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="年级"
                    prop="grade"
                    class="metting-select"
                >
                    <el-cascader
                        ref="cascaderAppointmentGradeRef"
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.grade"
                        :props="{
                            label: 'name',
                            multiple: false,
                            value: 'id',
                            checkStrictly: true,
                            children: 'child',
                            emitPath:false
                        }"
                        :options="meetingAppointmentForm.gradeOptions"
                        collapse-tags
                        :placeholder="'请选择年级'"
                        :clearable="true"
                        filterable
                        @change="handleAppointmentGradeChange"
                    ></el-cascader>
                    <!-- <el-select
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.grade"
                        filterable
                        clearable
                        placeholder="请选择年级"
                        @change="getYGradeName"
                    >
                        <el-option
                            v-for="item in meetingAppointmentForm.gradeOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select> -->
                </el-form-item>
                <!-- 预约时间 -->
                <div class="meeting_select-bxo">
                    <el-form-item
                        label="预约时间"
                        prop="meetingDetaTime"
                        class="meeting-time"
                    >
                        <el-date-picker
                            @change="getDateStrInfo"
                            v-model="meetingAppointmentForm.meetingDetaTime"
                            :picker-options="orderEndDate"
                            type="date"
                            placeholder="请选择日期"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="meeting-b-date">
                        <div class="br-line"></div>
                    </el-form-item>
                    <el-form-item
                        prop="startTime"
                        class="meeting-s-date"
                    >
                        <el-time-picker
                            @change="getStartTime"
                            v-model="meetingAppointmentForm.startTime"
                            :picker-options="meetingStartDate"
                            placeholder="开始时间"
                            value-format="HH:mm:ss"
                            format="HH:mm"
                        >
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item class="meeting-m-date">
                        <span>至</span>
                    </el-form-item>
                    <el-form-item
                        prop="endTime"
                        class="meeting-e-date"
                    >
                        <el-time-picker
                            class="input-width-medium"
                            @change="getEndTimeInfo"
                            v-model="meetingAppointmentForm.endTime"
                            :picker-options="meetingEndDate"
                            placeholder="结束时间"
                            value-format="HH:mm:ss"
                            format="HH:mm"
                        >
                        </el-time-picker>
                    </el-form-item>
                </div>
                <el-form-item
                    label="重复周期"
                    prop="repeatCycle"
                    class="metting-select"
                >
                    <div class="repeat-box">
                        <div v-for="item in meetingRepeatCycleOptions" :key="item.value" @click="chooseRepeatCycle(item)" class="repeat" >
                            <div class="icon" :class="{'iconOn': item.value == meetingAppointmentForm.repeatCycle}"><i class="el-icon-success"></i></div>
                            <div class="label" :class="{'labelOn': item.value == meetingAppointmentForm.repeatCycle}">{{ item.label }}</div>
                            <div class="jian" v-if="meetingAppointmentForm.repeatCycle == '2' && item.value == meetingAppointmentForm.repeatCycle">
                                <img src="@/assets/images/jian.png">
                            </div>
                        </div>
                    </div>
                    <!-- <el-select
                        class="input-width-medium"
                        v-model="meetingAppointmentForm.repeatCycle"
                        filterable
                        clearable
                        placeholder="请选择重复周期"
                    >
                        <el-option
                            v-for="item in meetingRepeatCycleOptions"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select> -->
                </el-form-item>
                <!-- 每周 -->
                <el-form-item style="margin-top: -10px" v-if="meetingAppointmentForm.repeatCycle == '2'" prop = "repeatCycleList">
                    <div class="week-box">
                        <div @click="chooseWeek(item)" v-for="item in meetingWeekOptions" :key="item.value" class="week" :class="{'weekOn': item.isChecked}">{{ item.label }}</div>
                    </div>
                </el-form-item>
                <!-- 自定义 -->
                <el-form-item
                    label="预定日期"
                    prop="repeatCycleList"
                    class="metting-select"
                    v-if="meetingAppointmentForm.repeatCycle == '3'"
                >

                <el-date-picker
                    type="dates"
                    v-model="meetingAppointmentForm.repeatCycleList"
                    :picker-options="cycleEndDate"
                    value-format="yyyy-MM-dd"
                    placeholder="选择一个或多个日期">
                </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="结束日期"
                    prop="cycleEndDate"
                    class="metting-select"
                    v-if="meetingAppointmentForm.repeatCycle == '1' || meetingAppointmentForm.repeatCycle == '2'"
                >

                <el-date-picker
                    v-model="meetingAppointmentForm.cycleEndDate"
                    :picker-options="cycleEndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="参会人"
                    prop="meetingParticipant"
                    class="join-meeting"
                    style="width: 452px"
                >
                    <div class="meeting-participant">
                        <div
                            class="meeting-participant-cell meeting-participant-add_button"
                            @click="addPeopleAppointmentClick"
                        >
                            <i class="el-icon-plus"></i>
                            <span>添加参会人</span>
                        </div>
                        <div
                            class="meeting-participant-cell meeting-participant-people"
                            v-for="item in meetingAppointmentForm.meetingParticipant"
                            :key="item.id"
                        >
                            <div class="meeting-participant-people-icon">
                                <img
                                    v-if="item.avatar && item.avatar !== ''"
                                    :src="item.avatar"
                                    alt=""
                                    title=""
                                />
                                <div
                                    v-else
                                    class="meeting-participant-people-icon-text"
                                >
                                    <span v-if="item.name">{{
                                        item.name.substring(0, 1)
                                    }}</span>
                                </div>
                            </div>
                            <div class="meeting-participant-people-wrap">
                                <div class="meeting-participant-people-name">
                                    {{ item.name }}
                                </div>
                                <div
                                    class="meeting-participant-people-close"
                                    @click="
                                        meetingASelectedPeopleRemoveClick(item)
                                    "
                                >
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    label="提醒"
                    prop="remindList"
                    class="remind-time"
                    style="margin-bottom: 10px;"
                >
                    <el-checkbox-group
                        style="margin-bottom: 0px; padding-bottom: 0px;"
                        v-model="meetingAppointmentForm.remindList"
                    >
                        <el-checkbox
                            border
                            v-for="item in meetingAppointmentForm.remindOptions"
                            :label="item.id"
                            :key="item.id"
                        >
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item
                    label="录屏/闪记"
                    prop="screenRecording"
                >
                    <el-switch
                        v-model="meetingAppointmentForm.screenRecording"
                        active-color="#3C7FFF"
                        inactive-color="#dcdfe6"
                        active-value="1"
                        inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <div class="meeting-form-button flex_end">
                <el-button
                    :disabled="disabledState"
                    @click="meetingAppointmentCancel"
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    :disabled="disabledState"
                    @click="meetingAppointmentConfirm('meetingAppointmentForm')"
                    >确认</el-button
                >
            </div>
        </dialog-wrapper>
        <!-- 发起、预约会议添加参会人 -->
        <!-- <dialog-wrapper :dialogObj="meetingLaunchAddPeopleData" @handleClose="meetingLaunchAddPeopleClose" class="meetings-dialogs meetings-dialogs-people">
			<div class="meetings-dialogs-add_people">
				<div class="add_people-cell add_people-form">
					<el-form :model="meetingLaunchAddPeopleForm" ref="meetingLaunchAddPeopleRef" @submit.native.prevent>
						<el-form-item>
							<el-input v-model="meetingLaunchAddPeopleForm.teacherName" prefix-icon="el-icon-search" clearable placeholder="搜索" @input="teacherInput"></el-input>
						</el-form-item>
                        <div class="meetingLaunchAddTab">
                            <span @click="changeLaunchType(1)" :class="{commonSpan:true,activeType:currentType==1}">按人员选</span>
                            <span @click="changeLaunchType(2)" :class="{commonSpan:true,activeType:currentType==2}">按架构选</span>
                        </div>
						<div style="height: 553px; overflow-y: auto;">
							<el-form-item prop="" v-show="this.currentType==1">
								<el-checkbox-group v-model="meetingLaunchAddPeopleForm.meetingPeopleList" @change="meetingPeopleListChange">
									<el-checkbox  v-for="item in meetingLaunchAddPeopleForm.meetingSelectPeopleList" :label="item.id" :key="item.id" :disabled="item.disabled">
										<div class="checkbox-people">
											<div class="checkbox-people-icon">
												<img v-if="item.avatar" :src="item.avatar" alt="" title="">
												<div v-else class="checkbox-people-icon-text">
													<span v-if="item.teacherName">{{ item.teacherName.substring(0,1) }}</span>
												</div>
											</div>
											<div class="checkbox-people-name">
												<span>{{ item.teacherName }}</span>
											</div>
										</div>
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
                            <el-form-item prop="" v-show="this.currentType==2">

							</el-form-item>
						</div>
					</el-form>
				</div>
				<div class="add_people-cell add_people-list">
					<div class="add_people-list-num">
						<div>已选择</div>
						<div>
							<span>（</span>
							<span>{{ meetingLaunchAddPeopleForm.meetingSelectedPeopleList.length }}</span>
							<span> / </span>
							<span style="color: #999;">{{ meetingLaunchAddPeopleForm.meetingSelectPeopleOperList.length }}</span>
							<span>）</span>
						</div>
					</div>
					<div class="add_people-list-info">
						<div class="add_people-list-info-wrap" v-for="item in meetingLaunchAddPeopleForm.meetingSelectedPeopleList" :key="item.id" >
							<div class="add_people-list-info-avatar">
								<img v-if="item.avatar" :src="item.avatar" alt="" title="">
								<div v-else class="add_people-list-info-avatar-text">
									<span v-if="item.teacherName">{{ item.teacherName.substring(0,1) }}</span>
								</div>
							</div>
							<div class="add_people-list-info-namer">
								<div class="add_people-list-real_name">{{ item.teacherName }}</div>
								<div class="add_people-list-remove_button" @click="meetingLaunchSelectPeopleRemoveClick(item)">
									<i class="el-icon-close"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="add_people-list-button">
						<el-button  type="" @click="meetingGetPeopleCancel">取消</el-button>
						<el-button  type="primary" @click="meetingGetPeopleConfirm">确认</el-button>
					</div>
				</div>
			</div>
		</dialog-wrapper> -->
        <tree-shape
            ref="treeShape"
            v-if="meetingLaunchAddPeopleData.dialogVisible"
            :title="meetingLaunchAddPeopleData.title"
            :show="meetingLaunchAddPeopleData.dialogVisible"
            :isGroup = "meetingLaunchAddPeopleData.isGroup"
            :groupOptions="groupOptions"
            :list="selTreeOptList"
            :selectObjData="selectObjData"
            :currentType="currentType"
            :meetingSelectPeopleList="
                meetingLaunchAddPeopleForm.meetingSelectPeopleList
            "
            :placeholder="'请输入姓名/手机号/部门名称'"
            @getNextLevelTreeList="getNextLevelTreeList"
            @determine="determine"
            @closeDialog="meetingGetPeopleCancel"
            @changeLaunchType="changeLaunchType"
            @meetingPeopleListChange="meetingPeopleListChange"
            @saveGroup="saveGroup"
            @deleteGroup="deleteGroup"
            @handlerGroupClick="handlerGroupClick"
        >
        </tree-shape>
        <!-- <tree-shape :show="meetingLaunchAddPeopleData.dialogVisible" @closeDialog="meetingLaunchAddClose"></tree-shape> -->
        <!-- 会议发起人选择 -->
        <dialog-wrapper
            :dialogObj="meetingOriginatePeopleData"
            @handleClose="meetingOriginatePeopleClose"
            class="meetings-dialogs meetings-dialogs-people"
        >
            <div class="meetings-dialogs-add_people">
                <div class="add_people-cell add_people-form">
                    <el-form
                        :model="meetingOriginatePeopleForm"
                        ref="meetingOriginatePeopleRef"
                        @submit.native.prevent
                    >
                        <el-form-item>
                            <el-input
                                style="width: 250px;"
                                class="meetingOriginatePeopleRef_search"
                                v-model="meetingOriginatePeopleForm.teacherName"
                                prefix-icon="el-icon-search"
                                clearable
                                :placeholder="'请输入姓名/手机号'"
                                @input="teacherLInput"
                            ></el-input>
                        </el-form-item>
                        <div style="height: 445px; overflow-y: auto">
                            <el-form-item prop="">
                                <el-checkbox-group
                                    v-model="
                                        meetingOriginatePeopleForm.meetingPeopleList
                                    "
                                    @change="meetingOriginatePeopleListChange"
                                    :max="1"
                                >
                                    <el-checkbox
                                        v-for="item in meetingOriginatePeopleForm.meetingSelectPeopleList"
                                        :label="item.id"
                                        :key="item.id"
                                        :disabled="item.disabled"
                                    >
                                        <div class="checkbox-people">
                                            <div class="checkbox-people-icon">
                                                <img
                                                    v-if="item.avatar"
                                                    :src="item.avatar"
                                                    alt=""
                                                    title=""
                                                />
                                                <div
                                                    v-else
                                                    class="checkbox-people-icon-text"
                                                >
                                                    <span
                                                        v-if="item.teacherName"
                                                        >{{
                                                            item.teacherName.substring(
                                                                0,
                                                                1,
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                            </div>
                                            <div class="checkbox-people-name">
                                                <span>{{
                                                    item.teacherName
                                                }}</span>
                                            </div>
                                        </div>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <div class="add_people-cell add_people-list">
                    <div class="add_people-list-num">
                        <div>已选择</div>
                        <div>
                            <span>（</span>
                            <span>{{
                                meetingOriginatePeopleForm
                                    .meetingSelectedPeopleList.length
                            }}</span>
                            <span> / </span>
                            <span style="color: #999">{{
                                meetingOriginatePeopleForm
                                    .meetingSelectPeopleList.length
                            }}</span>
                            <span>）</span>
                        </div>
                    </div>
                    <div class="add_people-list-info">
                        <div
                            class="add_people-list-info-wrap"
                            v-for="item in meetingOriginatePeopleForm.meetingSelectedPeopleList"
                            :key="item.id"
                        >
                            <div class="add_people-list-info-avatar">
                                <img
                                    v-if="item.avatar"
                                    :src="item.avatar"
                                    alt=""
                                    title=""
                                />
                                <div
                                    v-else
                                    class="add_people-list-info-avatar-text"
                                >
                                    <span v-if="item.teacherName">{{
                                        item.teacherName.substring(0, 1)
                                    }}</span>
                                </div>
                            </div>
                            <div class="add_people-list-info-namer">
                                <div class="add_people-list-real_name">
                                    {{ item.teacherName }}
                                </div>
                                <div
                                    class="add_people-list-remove_button"
                                    @click="
                                        meetingOriginatePeopleRemoveClick(item)
                                    "
                                >
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add_people-list-button">
                        <el-button
                            @click="meetingOriginatePeopleCancel"
                            >取消</el-button
                        >
                        <el-button
                            type="primary"
                            @click="meetingOriginatePeopleConfirm"
                            >确认</el-button
                        >
                    </div>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
let currsDetaStr = "";
let currsDetaSelect = "";
let currDeta = new Date();
// 当前年
let yearsDeta = currDeta.getFullYear();
// 当前月
let monthsDeta = currDeta.getMonth() + 1;
// 当前日
let daysDeta = currDeta.getDate();
// 当前时
let hourDeta = currDeta.getHours();
// 当前分钟
let minuteDeta = currDeta.getMinutes();
// 当前分钟15分钟后
let minuteSelectBefor = minuteDeta + 15;
// 当前秒
let secondsSelect = currDeta.getSeconds();

monthsDeta = monthsDeta < 10 ? "0" + monthsDeta : monthsDeta;
daysDeta = daysDeta < 10 ? "0" + daysDeta : daysDeta;
hourDeta = hourDeta < 10 ? "0" + hourDeta : hourDeta;
minuteDeta = minuteDeta < 10 ? "0" + minuteDeta : minuteDeta;
minuteSelectBefor =
    minuteSelectBefor < 10 ? "0" + minuteSelectBefor : minuteSelectBefor;
secondsSelect = secondsSelect < 10 ? "0" + secondsSelect : secondsSelect;

// 获取当前年月日
currsDetaStr = yearsDeta + "-" + monthsDeta + "-" + daysDeta;
// 获取当前时分秒(15分钟后)
currsDetaSelect = hourDeta + ":" + minuteSelectBefor + ":" + secondsSelect;
import {param2Obj, listToTree} from "@/libs/utils";
import {DialogWrapper, debounce} from "common-local";
import {mapState} from "vuex";
// import MyElCascader from '@/components/sub/MyEl/MyElCasader.vue'
import MyElCasader from "../../sub/MyEl/MyElCascader.vue";
import TreeShape from "./TreeShape/src/index.vue";
import {CommonModel} from "@/models/Common.js";
import {removeTreeShapeDialog, cleanTree} from "@/libs/utils.js";
import {SummaryTeacherBase} from "@/models/SummaryTeacherBase.js";
export default {
    name: "Initiation",
    components: {DialogWrapper, MyElCasader, TreeShape},
    data() {
        return {
            isShowGoWillBtn: true,
            isShowBeforeBtn: true,
            isShowOnlineAll: true,
            isShowWillAttend: true,
            isShowAttended: true,
            isShowOnlineLaunch: true,
            currentCom: "",
            meetingAllNum: 0,
            meetingNoAttendedNum: 0,
            meetingAttendedNum: 0,
            meetingLaunchNum: 0,
            // 已经选择属性数据对象 selectObjData
            selectObjData: [],
            selTreeOptList: [], //树形返回数据
            // 请求组织机构数据
            treeData: {
                schoolId: "",
                type: "",
                parentId: "",
                name: "",
                organType: "", //组织类型，类型包含organ时必传
                unionId: "", //部门组合id
                isDing: "1", //筛选钉钉用户
            },
            facultyStaff: {
                type: "teacher,organ",
                organType: "1,5",
            },
            // 添加参会人 选择种类 1 按人员选 2按架构选
            currentType: 1,
            // 按钮禁用控制
            disabledState: false,
            // 弹窗类型
            dialogType: "",
            checkboxNumber: 0,
            // 发起会议弹窗
            meetingLaunchData: {
                dialogVisible: false,
                title: "发起会议",
                width: "auto",
            },
            // 发起会议添加表单
            meetingLaunchForm: {
                sponsorId: "",
                meetingPeople: "",
                meetingTtitle: "",
                meetingType: "",
                meetingTypeOptions: [],
                subject: "",
                subjectName: "",
                subjectOptions: [],
                grade: "",
                gradeName: "",
                gradeId: "",//当前选中层级id
                parentName: "",//当前选中年级/级部的上级名称
                parentId: "",//当前选中年级/级部的上级id
                gradeOptions: [],
                // 固定参数
                productId: "cloud_campus",
                screenRecording: "1",
                meetingParticipant: [],
            },
            // 发起会议新增表单验证
            meetingLaunchRules: {
                meetingTtitle: [
                    {
                        required: true,
                        message: "请输入会议标题",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingType: [
                    {
                        required: true,
                        message: "请选择会议类型",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingParticipant: [
                    {
                        required: true,
                        message: "请选择参会人",
                        trigger: ["change"],
                    },
                ],
                screenRecording: [
                    {
                        required: true,
                        message: "请选择录屏闪记",
                        trigger: ["change"],
                    },
                ],
            },
            // 发起会议新增参会人弹窗
            meetingLaunchAddPeopleData: {
                dialogVisible: false,
                title: "添加参会人",
                width: "702px",
                isGroup: false
            },
            // 发起会议新增参会人弹窗表单
            meetingLaunchAddPeopleForm: {
                teacherName: "",
                meetingPeople: "",
                meetingPeopleList: [],
                meetingSelectPeopleList: [],
                meetingSelectPeopleOperList: [],
                meetingSelectedPeopleList: [],
            },
            // 选择发起人
            meetingOriginatePeopleData: {
                dialogVisible: false,
                title: "选择发起人",
                width: "702px",
                height: "520px"
            },
            // 发起会议新增参会人弹窗表单
            meetingOriginatePeopleForm: {
                search: "",
                teacherName: "",
                meetingPeople: "",
                meetingPeopleList: [],
                meetingSelectPeopleList: [],
                meetingSelectPeopleList: [],
                meetingSelectedPeopleList: [],
            },
            // 预约会议弹窗
            meetingAppointmentData: {
                dialogVisible: false,
                title: "预约会议",
                width: "auto",
            },
            pickermaxDate: "",
            pickerMinDate: "",
            defaultTime: [],
            // 限制日期选择
            orderEndDate: {
                disabledDate: (time) => {
                    let nowData = new Date()
		            nowData = new Date(nowData.setDate(nowData.getDate() - 1))
                    return time < nowData;
                }
            },
            meetingStartDate: {
                selectableRange: () => {
                    return currsDetaSelect + "-23:59:59"
                },
            },
            meetingEndDate: {
                selectableRange: currsDetaSelect + "-23:59:59",
            },
            // 限制结束日期选择
            cycleEndDate: {
                disabledDate: (time) => {
                    if(this.meetingAppointmentForm.repeatCycle == '1'){
                        // 每天
                        return (
                            // + 172800000

                            time.getTime() <
                            new Date(
                                this.meetingAppointmentForm.meetingDetaTime,
                            ).getTime() ||  time.getTime() > new Date(
                                this.meetingAppointmentForm.meetingDetaTime,
                            ).getTime() + 86400000 * 30
                        );
                    }else if(this.meetingAppointmentForm.repeatCycle == '2'){
                        // 每周
                        return (
                            // + 172800000

                            time.getTime() <
                            new Date(
                                this.meetingAppointmentForm.meetingDetaTime,
                            ).getTime() ||  time.getTime() > new Date(
                                this.meetingAppointmentForm.meetingDetaTime,
                            ).getTime() + 86400000 * 60
                        );
                    } else {
                        // 周
                        let length = this.meetingAppointmentForm.repeatCycleList? this.meetingAppointmentForm.repeatCycleList.length:0
                        return (
                            time.getTime() <
                            new Date(
                                this.meetingAppointmentForm.meetingDetaTime,
                            ).getTime() || length >= 20
                         )
                    }

                },
            },
            // 预约会议表单
            meetingAppointmentForm: {
                sponsorId: "",
                meetingPeople: [],
                meetingTtitle: "",
                meetingType: "",
                // 重复周期
                repeatCycle: "0",
                // 重复周期- 自定义时间
                repeatCycleList: [],
                meetingTypeOptions: [],
                subject: "",
                subjectName: "",
                subjectOptions: [],
                grade: "",
                gradeName: "",
                gradeId: "",//当前选中层级id
                parentName: "",//当前选中年级/级部的上级名称
                parentId: "",//当前选中年级/级部的上级id
                gradeOptions: [],
                // 固定参数
                productId: "cloud_campus",
                // 是否开启闪击录屏 (0否 1是 默认1)
                screenRecording: "1",
                // 参会人集合
                meetingParticipant: [],
                // 预约日期 (格式 yyyy-MM-dd)
                meetingDetaTime: currsDetaStr,
                // 预约开始时间
                startTime: "",
                // 预约结束时间
                endTime: "",
                // 存储预约结束时间
                endedTime: "",
                // 提醒时间(多选框)
                remindList: [],
                remindOptions: [],
            },
            // 预约会议新增表单验证
            meetingAppointmentRules: {
                meetingPeople: [
                    {
                        required: true,
                        message: "请选择会议发起人",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingTtitle: [
                    {
                        required: true,
                        message: "请输入会议标题",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingType: [
                    {
                        required: true,
                        message: "请选择会议类型",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingDetaTime: [
                    {
                        required: true,
                        message: "请选择预约日期",
                        trigger: ["change", "blur"],
                    },
                ],
                cycleEndDate: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: ["change", "blur"],
                    },
                ],
                repeatCycleList: [
                    {
                        required: true,
                        message: "请选择时间",
                        trigger: ["change", "blur"],
                    },
                ],
                repeatCycle: [
                    {
                        required: true,
                        message: "请选择重复周期",
                        trigger: ["change", "blur"],
                    },
                ],
                startTime: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: ["change", "blur"],
                    },
                ],
                endTime: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: ["change", "blur"],
                    },
                ],
                remindList: [
                    {
                        required: true,
                        message: "请选择提醒时间",
                        trigger: ["change", "blur"],
                    },
                ],
                meetingParticipant: [
                    {
                        required: true,
                        message: "请选择参会人",
                        trigger: ["change"],
                    },
                ],
                screenRecording: [
                    {
                        required: true,
                        message: "请选择录屏闪记",
                        trigger: ["change"],
                    },
                ],
            },
            meetingRepeatCycleOptions:[
                {
                    value: "0",
                    label: "不重复",
                },
                {
                    value: "1",
                    label: "每天",
                },{
                    value: "2",
                    label: "每周",
                },{
                    value: "3",
                    label: "自定义",
                }
            ],
            meetingWeekOptions:[
                {
                    value: "2",
                    label: "一",
                    isChecked: false,
                },
                {
                    value: "3",
                    label: "二",
                    isChecked: false,
                },{
                    value: "4",
                    label: "三",
                    isChecked: false,
                },{
                    value: "5",
                    label: "四",
                    isChecked: false,
                },{
                    value: "6",
                    label: "五",
                    isChecked: false,
                },{
                    value: "7",
                    label: "六",
                    isChecked: false,
                },{
                    value: "1",
                    label: "日",
                    isChecked: false,
                }
            ],
            groupOptions:[],
            // 发起会议时的loading
            createdLoading:false,
        };
    },
    computed: {
        ...mapState({
            projectMark: (state) => state.projectMark,
        }),
        ...mapState({
            permissions: (state) => state.permissions,
        }),
        allImg() {
            return require("@/assets/images/meeting/all_icon.png");
        },
        willImg() {
            return require("@/assets/images/meeting/will_icon.png");
        },
        doneImg() {
            return require("@/assets/images/meeting/done_icon.png");
        },
        mineImg() {
            return require("@/assets/images/meeting/mine_icon.png");
        },
        tipImg() {
            return require("@/assets/images/meeting/tip_icon.png");
        },
        rightImg() {
            return require("@/assets/images/meeting/turn_right.png");
        },
        planeImg() {
            return require("@/assets/images/meeting/gowill_plane.png");
        },
        timeImg() {
            return require("@/assets/images/meeting/before_time.png");
        }
    },
    created() {
        this.getShowBtn();
        this.getMeetingCount();
        this.getDictList();
        this.getTypes();
        this.getGradList();
        this.getSubjectList();
        this.getTeacherList();
        this.getTeachersList();
        this.getGroupList()
        this.treeData.schoolId = this.$store.state.schoolId;
        this.treeData.type = this.facultyStaff.type;
        this.treeData.organType = this.facultyStaff.organType;
        this.getTreeData();

    },
    methods: {
        /**
         * @Description: 选择周
         * @Author: 王梦瑶
         * @Date: 2024-09-18 15:21:36
         */
         chooseWeek(item){
            item.isChecked = !item.isChecked
            let arr = []
            this.meetingWeekOptions.forEach((i)=>{
                if(i.isChecked){
                    arr.push(i.value)
                }
            })
            this.meetingAppointmentForm.repeatCycleList = arr
         },
        /**
         * @Description: 切换周期
         * @Author: 王梦瑶
         * @Date: 2024-09-23 10:24:20
         * @param {*} time
         */
         chooseRepeatCycle(val){
            // 切换周期需要把某些字段清空
            this.meetingAppointmentForm.repeatCycleList = []
            if( val.value == '2' ){
                // 周
                let today = new Date();
                let week = today.getDay();
                let index = 1
                if(week == 7){
                    index = 0
                }else{
                    index = this.meetingWeekOptions.findIndex(item => item.value == week+1);
                }
                // let
                this.meetingWeekOptions[index].isChecked = true;
                let arr = []
                this.meetingWeekOptions.forEach((i)=>{
                    if(i.isChecked){
                        arr.push(i.value)
                    }
                })
                this.meetingAppointmentForm.repeatCycleList = arr
                console.log("hhhhhhhh--------this.meetingWeekOptions",this.meetingAppointmentForm.repeatCycleList)
            }

            if(this.meetingAppointmentForm.cycleEndDate){
                this.meetingAppointmentForm.cycleEndDate = null
            }
            // this.meetingAppointmentForm.cycleEndDate = null
            this.meetingAppointmentForm.repeatCycle = val.value
        },
        meetingStartDateCom (time) {
            console.log(time,'time----')
        },
        /**
         * @Description: 存为分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 15:04:11
         */
         saveGroup(data) {
            let query = {
                name: data.name,
                userList: data.selectTreeNodeData,
                checked:false
            }
            let arr = this.groupOptions?this.groupOptions:[]
            arr.push(query)
            arr = arr.map((item)=>{
                item.checked = false
                return item
            })
            console.log("arr",arr,'==========')
            if(arr.length == 0){
                this.$message.error('所选人员不能为空')
                return
            }
            // this.groupOptions.push(query)
            let params = {
				schoolId: this.$store.state.schoolId,
                configType:"groupType",
				config: JSON.stringify(arr),
                userId:this.$store.state.userId,
                menuId:1
			};
            const summaryTeacherBase = new SummaryTeacherBase();
            summaryTeacherBase
            .saveUserPreferences(params)
            .then( async (res) => {
                if (res.data.code == "200") {
                    this.$message.success('保存成功');
                    // await this.getUserFilter();
                    // await this.getNewCloseWidth();
                    // await this.getDesignList()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.getGroupList()
            });
            // /school/schoolUserPreferenceDynamicConfig/save
        },
        /**
         * @Description: 删除分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 16:22:37
         */
         deleteGroup(index){
            this.$confirm('删除后将无法恢复，是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 确认
                console.log(index,'点击的确定')
                this.groupOptions.splice(index,1)
                let params = {
                    schoolId: this.$store.state.schoolId,
                    configType:"groupType",
                    config: JSON.stringify(arr),
                    userId:this.$store.state.userId
                };
                const summaryTeacherBase = new SummaryTeacherBase();
                summaryTeacherBase
                .saveUserPreferences(params)
                .then( async (res) => {
                    if (res.data.code == "200") {
                        this.$message.success('删除成功');
                        this.getGroupList()
                        // await this.getUserFilter();
                        // await this.getNewCloseWidth();
                        // await this.getDesignList()
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            }).catch(() => {
                // 取消
            });
         },
        /**
         * @Description: 获取分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 15:50:08
         */
        getGroupList(){
            const summaryTeacherBase = new SummaryTeacherBase()
            summaryTeacherBase
            .getUserPreferences({
                menuId: 1,
                configType:"groupType"
            })
            .then((res) => {
                if (res.data.code == "200") {
                    this.groupOptions = JSON.parse(
                        res.data.data.config,
                    )
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        /**
         * @Description: 选择/取消选择分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 17:11:16
         */
         handlerGroupClick(index){
            // this.groupOptions[index].checked = !this.groupOptions[index].checked
            console.log('index--------------',index,this.groupOptions[index].checked, this.$refs.treeShape.selectTreeNodeData)
            // // 如果是选中状态
            if(this.groupOptions[index].checked){
                this.groupOptions[index].userList.forEach( user =>{
                    if(this.$refs.treeShape.selectTreeNodeData.findIndex(item => item.id == user.id) == -1  && !user.disabled){
                        //
                        this.$refs.treeShape.selectTreeNodeData.push(user)
                    }
                })
            } else {
                // 取消选中
                // 循环一下哪个选中了
                let arr = []
                this.groupOptions.forEach((item,ind)=>{
                    if(item.checked && index != ind ){
                        arr.push(...item.userList)
                    }
                })
                this.groupOptions[index].userList.forEach( user =>{
                    let groupIndex = this.$refs.treeShape.selectTreeNodeData.findIndex(item => item.id == user.id)
                    let arrInd = arr.findIndex(item => item.id == user.id)
                    if(groupIndex != -1 && arrInd == -1){
                        //
                        this.$refs.treeShape.selectTreeNodeData.splice(groupIndex,1)
                    }
                })
            }

        },
        /**
         * @Description: 选择/取消选择分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 17:11:16
         */
         handlerGroupAllClick(){
            this.groupOptions.forEach(item=>{
            })
            // this.selectObjData
         },
        /**
         * @Description: 获取去发起/去预约会议按钮的权限
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-22 11:34:29
         */
        getShowBtn() {
            // tab 的权限
            console.log("this.permissions--------", this.permissions);
            let res = this.permissions.indexOf("online:start");
            let res2 = this.permissions.indexOf("online:appointment");
            let res3 = this.permissions.indexOf("online:all");
            let res4 = this.permissions.indexOf("online:willAttend");
            let res5 = this.permissions.indexOf("online:attended");
            let res6 = this.permissions.indexOf("online:launch");
            if (res === -1) {
                this.isShowGoWillBtn = false;
            }
            if (res2 === -1) {
                this.isShowBeforeBtn = false;
            }
            if (res3 === -1) {
                this.isShowOnlineAll = false;
            }
            if (res4 === -1) {
                this.isShowWillAttend = false;
            }
            if (res5 === -1) {
                this.isShowAttended = false;
            }
            if (res6 === -1) {
                this.isShowOnlineLaunch = false;
            }
        },
        // 点击所有会议，跳转页面
        toAllOnline() {
            this.currentCom = "AllMeeting";
            this.$emit("changeCom", this.currentCom);
        },
        // 点击待我参会，跳转页面
        toWillAttend() {
            this.currentCom = "WaitMeeting";
            this.$emit("changeCom", this.currentCom);
        },
        // 点击我已参会，跳转页面
        toAttended() {
            this.currentCom = "HasMeeting";
            this.$emit("changeCom", this.currentCom);
        },
        // 点击我发起的，跳转页面
        toOnlineLaunch() {
            this.currentCom = "MyMeeting";
            this.$emit("changeCom", this.currentCom);
        },
        /**
         * @Description: 获取去会议次数统计
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-22 11:34:58
         */
        getMeetingCount() {
            this._fet("/school/schoolMeetingAnalysis/getMeetingCount").then(
                (res) => {
                    if (res.data.code === "200") {
                        this.meetingAllNum = res.data.data.all;
                        this.meetingNoAttendedNum = res.data.data.noAttended;
                        this.meetingAttendedNum = res.data.data.attended;
                        this.meetingLaunchNum = res.data.data.launch;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /**
         * @Description: 更改类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 14:05:27
         */
        changeLaunchType(type) {
            this.currentType = type;
        },
        /**
         * @Description: 返回组织机构结果
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 14:54:27
         * @param {*} data
         */
        determine(data) {
            console.log(data, "????");
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.selectObjData = [...data]; //回显数据
            this.meetingLaunchForm.meetingParticipant = [...data]; //展示数据
            this.meetingLaunchForm.meetingParticipant =
                this.meetingLaunchForm.meetingParticipant.map((item) => {
                    return {
                        ...item,
                        teacherName: item.name,
                    };
                });
            this.meetingAppointmentForm.meetingParticipant = [...data]; //展示数据
            this.meetingAppointmentForm.meetingParticipant =
                this.meetingAppointmentForm.meetingParticipant.map((item) => {
                    return {
                        ...item,
                        teacherName: item.name,
                    };
                });
            this.meetingLaunchAddPeopleData.dialogVisible = false;
        },
        /**
         * @Description: 组织机构请求下级
         * @DoWhat: 下级查询
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-06-06 14:49:37
         * @param {*} data
         */
        async getNextLevelTreeList(data) {
            const {id, name} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
        },
        /**
         * @Description:获取树型数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 08:57:16
         */
        async getTreeData() {
            const commonModel = new CommonModel();
            await commonModel.getAddressBookList(this.treeData).then((res) => {
                console.log(res, "getTreeData");
                let teacherId = this.$store.state.teacherId || "";
                if (this.meetingAppointmentForm.meetingPeople[0]) {
                    teacherId = this.meetingAppointmentForm.meetingPeople[0].id;
                }
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                    let electTreeNodeData = [];
                    if(this.meetingLaunchAddPeopleData.dialogVisible){
                        electTreeNodeData = this.$refs.treeShape.selectTreeNodeData;
                    }
                    this.selTreeOptList = this.selTreeOptList.map((item) => {
                        let checked = false
                        if(this.meetingLaunchAddPeopleData.dialogVisible && electTreeNodeData.length > 0){
                            let ids = electTreeNodeData.map(electTreeItem => electTreeItem.id);
                            checked = ids.includes(item.id);
                        }
                        return {
                            ...item,
                            disabled: teacherId == item.id ? true : false,
                            checked: checked,

                        };
                    });
                    this.selectObjData = [];
                    let teacherIds = this.meetingAppointmentForm.meetingPeople;
                    this.groupOptions.forEach(item => {
                        item.checked = false
                        item.userList.forEach(userItem => {
                            userItem.disabled = teacherId == userItem.id ? true : false
                        })
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 年级下拉
        getGradList() {
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            // Object.assign(obj, {jobScope: ["1", "2", "3"]});
            this._fet(
                "/school/schoolOrgan/getGradeOfTree",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    let options = cleanTree(res.data.data,'child');

                    this.meetingLaunchForm.gradeOptions = options;
                    this.meetingAppointmentForm.gradeOptions = options;

                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 预约时间获取日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-02 27 08:49:50
         */
        getDateStrInfo(val) {
            console.log(val,'val')
            let currsDetaSelect = "";
            let currsDetaLiSelect = "";
            if(this.meetingAppointmentForm.repeatCycleList && this.meetingAppointmentForm.repeatCycle == '3'){
                this.meetingAppointmentForm.repeatCycleList = []
            }
            if(this.meetingAppointmentForm.cycleEndDate){
               this.meetingAppointmentForm.cycleEndDate = null
            }
            let currDeta = new Date();
            // 当前年
            let yearsDeta = currDeta.getFullYear();
            // 当前月
            let monthDeta = currDeta.getMonth() + 1;
            // 当前日
            let daysDeta = currDeta.getDate();
            // 当前时
            let hourSelect = currDeta.getHours();
            let hourSelectED = null;
            // 当前分钟
            let minuteSelect = currDeta.getMinutes();
            // 当前分钟
            let minuteSelectBefor = minuteSelect + 15;
            let minuteSelectedBefor = minuteSelectBefor + 1;
            // 当前秒
            let secondsSelect = currDeta.getSeconds();
            if (hourSelect == 23) {
                hourSelectED = 23;
            } else {
                hourSelectED = hourSelect + 2;
            }

            monthDeta = monthDeta < 10 ? "0" + monthDeta : monthDeta;
            daysDeta = daysDeta < 10 ? "0" + daysDeta : daysDeta;
            hourSelect = hourSelect < 10 ? "0" + hourSelect : hourSelect;
            minuteSelect =
                minuteSelect < 10 ? "0" + minuteSelect : minuteSelect;
            minuteSelectBefor =
                minuteSelectBefor < 10
                    ? "0" + minuteSelectBefor
                    : minuteSelectBefor;
            secondsSelect =
                secondsSelect < 10 ? "0" + secondsSelect : secondsSelect;
            currsDetaSelect =
                hourSelect + ":" + minuteSelectBefor + ":" + secondsSelect;
            currsDetaLiSelect =
                hourSelectED + ":" + minuteSelectBefor + ":" + secondsSelect;
            if (val) {
                if ((Number(val.substring(0, 4)) > Number(yearsDeta)) || (val.substring(5, 7) > monthDeta)) {
                    this.meetingStartDate.selectableRange = "00:00:00-23:59:59";
                } else if (val.substring(5, 7) == monthDeta) {
                    if (val.substring(8, 10) > daysDeta) {
                        this.meetingStartDate.selectableRange =
                            "00:00:00-23:59:59";
                    } else {
                        this.meetingStartDate.selectableRange =
                            currsDetaSelect + "-23:59:59";
                        this.meetingAppointmentForm.startTime = currsDetaSelect;
                        this.meetingAppointmentForm.endTime = currsDetaLiSelect;
                    }
                }
            }
        },
        /**
         * @Description: 预约时间获取开始时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-02-27 08:50:02
         */
        getStartTime(val) {
            let startD = "";
            let startE = "";
            let dateTime = val;
            let h = "";
            let endH = "";
            let m = "";
            let s = "";

            if (dateTime) {
                h = dateTime.substring(0, 2);
                m = dateTime.substring(3, 5);
                s = dateTime.substring(6, 8);
                if (h == 22) {
                    endH = 23;
                    startE = "23:59:59";
                } else if (h == 23) {
                    endH = 23;
                    startE = "23:59:59";
                } else {
                    endH = Number(h) + 2;
                    endH = endH < 10 ? "0" + endH : endH;
                    startE = endH + ":" + m + ":" + s;
                }

                startD = h + ":" + m + ":" + s;

                // 赋值预约时间结束结束时间可选范围
                this.meetingEndDate.selectableRange = startD + "-23:59:59";
                this.meetingAppointmentForm.endTime = startE;
            }

            // 如果已经选择结束时间，再次选中开始时间后自动赋值结束时间
            // if (this.meetingAppointmentForm.endedTime != "") {
            // 	this.meetingAppointmentForm.endTime = startD
            // }
        },
        /**
         * @Description: 预约时间获取结束时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-02-27 08:50:17
         */
        getEndTimeInfo(val) {
            this.meetingAppointmentForm.endedTime = val;
        },
        /**
         * @Description: 验证账号能不能发起会议
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-2-13 11:52:36
         */
        verifyMeeting() {
            this._fet("/school/schoolMeetingAnalysis/verify", {
                sponsorId: this.$store.state.teacherId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.meetingLaunchForm.meetingPeople =
                        this.$store.state.userName;
                    this.meetingLaunchData.dialogVisible = true;
					this.meetingLaunchForm.meetingParticipant = []
                } else {
                    this.$alert(
                        "当前账号在钉钉中不存在，无权发起会议。",
                        "提示",
                        {
                            confirmButtonText: "确定",
                            type: "warning",
                            showClose: false,
                            center: true,
                        },
                    );
                }
            });
        },
        /**
         * @Description: 获取提醒时间列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-2-10 14:27:31
         */
        getDictList() {
            this.meetingAppointmentForm.remindList = [];
            this._fet("/school/common/getDictList", {
                dictKey: "remindTime",
            }).then((res) => {
                if (res.status === 0) {
                    if (res.data.data.length > 0) {
                        this.meetingAppointmentForm.remindList.push(
                            res.data.data[2].id,
                        );
                    }
                    this.meetingAppointmentForm.remindOptions = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-01-03 17:18:58
         */
        getTypes() {
            this._fet("/school/schoolDictValue/getDictValueList", {
                dickKeyList: ["meetingType"],
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.status === 0) {
                    let dataList = res.data;
                    if (dataList.length > 0) {
                        dataList.forEach((item) => {
                            item.value = item.id;
                            item.label = item.name + "(" + item.remark + ")";
                        });
                    }
                    this.meetingLaunchForm.meetingTypeOptions = dataList;
                    this.meetingAppointmentForm.meetingTypeOptions = dataList;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取科目列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-1-4 18:27:45
         */
        getSubjectList() {
            this._fet("/school/schoolDictValue/getDictValueList", {
                schoolId: this.$store.state.schoolId,
                dickKeyList: ["teachingSubjects"],
            }).then((res) => {
                if (res.status === 0) {
                    let dataList = res.data;
                    dataList.forEach((item) => {
                        item.value = item.id;
                        item.label = item.name;
                    });
                    this.meetingLaunchForm.subjectOptions = dataList;
                    this.meetingAppointmentForm.subjectOptions = dataList;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 教师列表
        // isDing 是否为钉钉用户，1是，0否
        getTeacherList(teacherName) {
            teacherName = teacherName || "";
            this._fet("/school/schoolTeacher/listByConditionNormal", {
                isDing: "1",
                teacherName: teacherName,
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    let dataList = res.data.data;
                    dataList.forEach((item) => {
                        item.id = item.id;
                        item.teacherName = item.teacherName;
                        item.disabled = false;
                        item.avatar = "";
                    });
                    this.meetingLaunchAddPeopleForm.meetingSelectPeopleList =
                        dataList;
                    this.meetingOriginatePeopleForm.meetingSelectPeopleList =
                        dataList;
                    console.log(this.meetingOriginatePeopleForm.search);
                    console.log(this.dialogType);
                    // 发参会人搜索后禁选发起人
                    if (this.meetingOriginatePeopleForm.search == "search") {
                        let teacherId = "";
                        // 获取会议发起人的主键，限制选择参会人时不可选择会议发起人
                        if (this.dialogType == "launchDialog") {
                            // 发起会议时
                            teacherId = this.$store.state.teacherId;
                        } else if (this.dialogType == "appointmentDialog") {
                            // 预约会议时
                            teacherId =
                                this.meetingAppointmentForm.meetingPeople[0].id;
                            console.log(
                                "this.meetingAppointmentForm.meetingPeople",
                                this.meetingAppointmentForm.meetingPeople,
                            );
                        }
                        this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                            (item) => {
                                if (item.id === teacherId) {
                                    item.disabled = true;
                                } else {
                                    item.disabled = false;
                                }
                            },
                        );
                        teacherId = "";

                        this.meetingOriginatePeopleForm.search = "";
                    }

                    // if(this.meetingOriginatePeopleForm.search == "search") {
                    // 	let teacherId = "";
                    // 	teacherId = this.meetingAppointmentForm.meetingPeople[0].id;
                    // 	this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(item => {
                    // 		if(item.id === teacherId) {
                    // 			item.disabled = true;
                    // 		} else {
                    // 			item.disabled = false;
                    // 		}
                    // 	});
                    // 	teacherId =  "";
                    // 	this.meetingOriginatePeopleForm.search = "";
                    // }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 教师列表
        // isDing 是否为钉钉用户，1是，0否
        getTeachersList(teacherName) {
            teacherName = teacherName || "";
            this._fet("/school/schoolTeacher/listByConditionNormal", {
                isDing: "1",
                teacherName: teacherName,
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    let dataList = res.data.data;
                    dataList.forEach((item) => {
                        item.id = item.id;
                        item.teacherName = item.teacherName;
                        item.disabled = false;
                        item.avatar = "";
                    });

                    this.meetingLaunchAddPeopleForm.meetingSelectPeopleOperList =
                        dataList;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 添加参会人员弹窗教师姓名搜索监听
        teacherInput(val) {
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            // 待选人员数组集合
            let dataList =
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleOperList;
            // 左侧选中人员数组集合
            let dataedList = this.meetingLaunchAddPeopleForm.meetingPeopleList;
            //console.log(dataList)
            //console.log(dataedList)
            for (var j = 0, len = dataList.length; j < len; j++) {
                for (var k = 0, leng = dataedList.length; k < leng; k++) {
                    if (dataedList[k] === dataList[j].id) {
                        this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.push(
                            dataList[j],
                        );
                    }
                }
            }
            // console.log(this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList)
            // 调用教师查询接口
            this.getTeacherList(val);
            this.meetingOriginatePeopleForm.search = "search";
        },
        // 添加发起人员弹窗教师姓名搜索监听
        teacherLInput(val) {
            // 调用教师查询接口
            this.getTeacherList(val);
        },

        // 添加参会人关闭弹窗
        meetingLaunchAddPeopleClose() {
            this.dialogType = "";
            this.meetingLaunchForm.teacherName = "";
            this.meetingLaunchAddPeopleData.dialogVisible = false;
            this.meetingOriginatePeopleForm.teacherName = "";
            this.meetingLaunchAddPeopleForm.teacherName = "";

            // 发起会议新增参会人关闭的时候情况两侧人员选择状态
            // 左侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            // 赋值已经确认选中的人员信息
            for (
                let j = 0;
                j < this.meetingAppointmentForm.meetingParticipant.length;
                j++
            ) {
                this.meetingLaunchAddPeopleForm.meetingPeopleList.push(
                    this.meetingAppointmentForm.meetingParticipant[j].id,
                );
            }
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList =
                this.meetingAppointmentForm.meetingParticipant;
            this.getTeacherList();
        },
        /**
         * @Description: 监听获取选中人员信息(主键)
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-02 10:43:16
         * @param {*} list
         */
        meetingPeopleListChange(list) {
            // 先重置 按人员选择的数据
            this.meetingLaunchAddPeopleForm.meetingSelectPeopleList =
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.map(
                    (item) => {
                        return {
                            ...item,
                            checked: false,
                        };
                    },
                );
            // 再过滤 选中的 用于回显数据
            this.meetingLaunchAddPeopleForm.meetingSelectPeopleList =
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.map(
                    (item) => {
                        let index = list.findIndex((i) => {
                            return i.id == item.id;
                        });
                        if (index !== -1) {
                            return {
                                ...item,
                                checked: true,
                            };
                        }
                        return item;
                    },
                );
            // // 待选人员数组集合
            // let dataList = this.meetingLaunchAddPeopleForm.meetingSelectPeopleOperList;
            // // 左侧选中人员数组集合
            // let dataedList = this.meetingLaunchAddPeopleForm.meetingPeopleList

            // // 先清空一下选中人员列表
            // this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            // // console.log(dataedList)
            // // 人员主键相同新增到选中数组中
            // for(var j = 0, len = dataList.length; j < len; j++) {
            // 	for(var k = 0, leng = dataedList.length; k < leng; k++) {
            // 		if(dataedList[k] === dataList[j].id) {
            // 			this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.push(dataList[j])
            // 		}
            // 	}
            // }
        },
        /**发起会议新增参会人取消
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-02 11:54:01
         */
        meetingGetPeopleCancel() {
            console.log("参会人取消");
            this.dialogType = "";
            this.treeData.parentId = null;
            this.treeData.name = "";
            removeTreeShapeDialog();
            this.meetingLaunchAddPeopleData.dialogVisible = false;
            this.meetingLaunchForm.teacherName = "";
            // meetingLaunchAddPeopleForm.meetingSelectPeopleList
            // if(this.meetingLaunchAddPeopleForm.meetingPeopleList.length == 0) {
            // 	// 左侧选中人员数组集合
            // 	this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 	// 右侧选中人员数组集合
            // 	this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            // }
            // 发起会议新增参会人取消的时候情况两侧人员选择状态
            // 左侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            this.meetingOriginatePeopleForm.teacherName = "";
            this.meetingLaunchAddPeopleForm.teacherName = "";
            // 赋值已经确认选中的人员信息
            for (
                let j = 0;
                j < this.meetingAppointmentForm.meetingParticipant.length;
                j++
            ) {
                this.meetingLaunchAddPeopleForm.meetingPeopleList.push(
                    this.meetingAppointmentForm.meetingParticipant[j].id,
                );
            }
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList =
                this.meetingAppointmentForm.meetingParticipant;
            this.getTeacherList();
        },
        // 发起会议新增参会人确定
        meetingGetPeopleConfirm() {
            if (this.dialogType === "launchDialog") {
                // 发起会议
                this.meetingLaunchForm.meetingParticipant =
                    this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
            } else if (this.dialogType === "appointmentDialog") {
                // 预约会议
                this.meetingAppointmentForm.meetingParticipant =
                    this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
            }
            this.meetingLaunchAddPeopleData.dialogVisible = false;
            this.dialogType = "";
        },
        // 添加参会人移除选中人员
        meetingLaunchSelectPeopleRemoveClick(param) {
            if (this.dialogType === "launchDialog") {
                // 发起会议
                // 左侧选中人员数组集合
                let dataList =
                    this.meetingLaunchAddPeopleForm.meetingPeopleList;
                // 右侧选中人员数组集合
                let dataSelectedList =
                    this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
                for (var k = 0, len = dataList.length; k < len; k++) {
                    if (param.id === dataList[k]) {
                        this.meetingLaunchAddPeopleForm.meetingPeopleList.splice(
                            k,
                            1,
                        );
                    }
                }
                for (var j = 0, len = dataSelectedList.length; j < len; j++) {
                    if (param.id === dataSelectedList[j].id) {
                        this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.splice(
                            j,
                            1,
                        );
                    }
                }
            } else if (this.dialogType === "appointmentDialog") {
                // 预约会议
                // 左侧选中人员数组集合
                let dataList =
                    this.meetingLaunchAddPeopleForm.meetingPeopleList;
                // 右侧选中人员数组集合
                let dataSelectedList =
                    this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
                for (var k = 0, len = dataList.length; k < len; k++) {
                    if (param.id === dataList[k]) {
                        this.meetingLaunchAddPeopleForm.meetingPeopleList.splice(
                            k,
                            1,
                        );
                    }
                }
                for (var j = 0, len = dataSelectedList.length; j < len; j++) {
                    if (param.id === dataSelectedList[j].id) {
                        this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.splice(
                            j,
                            1,
                        );
                    }
                }
            }
        },

        // 发起会议
        meetingLaunchClick() {
            // 判断当前登录人账号是否存在钉钉中
            this.verifyMeeting();
        },
        /**
         * @Description: 发起会议添加参会人
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-07 17:25:56
         */
        async addPeopleClick() {
            this.meetingLaunchAddPeopleData.title = "添加参会人";
            this.dialogType = "launchDialog";
            this.checkboxNumber =
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.length;

            // 获取会议发起人的主键，限制选择参会人时不可选择会议发起人
            let teacherId = this.$store.state.teacherId;
            this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                (item) => {
                    if (item.id === teacherId) {
                        item.disabled = true;
                    } else {
                        item.disabled = false;
                    }
                },
            );
            // 如果参会人已经选中择重新打开选择列表时需要重新赋值
            if (this.meetingLaunchForm.meetingParticipant.length > 0) {
                // 选择参会人弹窗右侧列表
                let dataList = [];
                dataList = this.meetingLaunchForm.meetingParticipant;
                this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
                this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList =
                    dataList;
                for (let j = 0; j < dataList.length; j++) {
                    // 左侧选中人员数组集合
                    this.meetingLaunchAddPeopleForm.meetingPeopleList.push(
                        dataList[j].id,
                    );
                }
            }
            this.treeData.parentId = ""; //回显时 展示所有数据
            await this.getTreeData(); //重置数据后，需要回显的数据也会清空
            this.selectObjData = [...this.meetingLaunchForm.meetingParticipant]; //重新赋值
            this.meetingLaunchAddPeopleData.dialogVisible = true;
        },
        /**
         * @Description: 发起会议弹窗移除选中人员
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-07 17:08:57
         * @param {*} item
         */
        meetingLaunchSelectedPeopleRemoveClick(item) {
            // 删除回显的数据
            let index1 = this.selectObjData.findIndex((i) => {
                return i.id == item.id;
            });
            this.selectObjData.splice(index1, 1);
            // 删除展示的数据
            let index2 = this.meetingLaunchForm.meetingParticipant.findIndex(
                (i) => {
                    return i.id == item.id;
                },
            );
            this.meetingLaunchForm.meetingParticipant.splice(index2, 1);
            // // 左侧选中人员数组集合
            // let dataList = this.meetingLaunchAddPeopleForm.meetingPeopleList;
            // // 右侧选中人员数组集合
            // let dataSelectedList = this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
            // // 发起会议弹窗选中人员信息数组集合
            // let selectedPeopleList = this.meetingLaunchForm.meetingParticipant;
            // // 发起会议弹窗选中人员信息移除
            // for(var i = 0, len = selectedPeopleList.length; i < len; i++) {
            // 	if(param.id === selectedPeopleList[i]) {
            // 		this.meetingLaunchForm.meetingParticipant.splice(i, 1);
            // 	}
            // }
            // // 添加参会人弹窗左侧选中人员信息移除
            // for(var k = 0, len = dataList.length; k < len; k++) {
            // 	if(param.id === dataList[k]) {
            // 		this.meetingLaunchAddPeopleForm.meetingPeopleList.splice(k, 1);
            // 	}
            // }
            // // 添加参会人弹窗右侧选中人员信息移除
            // for(var j = 0, len = dataSelectedList.length; j < len; j++) {
            // 	if(dataSelectedList[j] && dataSelectedList[j].id === param.id) {
            // 		this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.splice(j, 1);
            // 	}
            // }
        },

        // 获取科目名称
        getLSubjectName(val) {
            let dataList = this.meetingLaunchForm.subjectOptions;
            if (val) {
                for (let j = 0; j < dataList.length; j++) {
                    if (val === dataList[j].id) {
                        this.meetingLaunchForm.subjectName = dataList[j].name;
                    }
                }
            } else {
                this.meetingLaunchForm.subjectName = "";
            }
        },
        // 获取年级名称
        getLGradeName(val) {
            let dataList = this.meetingLaunchForm.gradeOptions;
            if (val) {
                for (let j = 0; j < dataList.length; j++) {
                    if (val === dataList[j].id) {
                        this.meetingLaunchForm.gradeName = dataList[j].name;
                    }
                }
            } else {
                this.meetingLaunchForm.gradeName = "";
            }
        },
        /**
         * @Description: 发起会议弹窗关闭按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-07 17:12:08
         */
        meetingLaunchClose() {
            this.selectObjData = [];
            this.meetingLaunchForm.meetingParticipant = [];
            this.meetingAppointmentForm.meetingParticipant = [];
            this.meetingOriginatePeopleForm.meetingSelectedPeopleList = [];
            this.treeData.parentId = null;
            this.treeData.name = null;
            // this.meetingOriginatePeopleForm.meetingSelectPeopleList=[]
            this.meetingLaunchData.dialogVisible = false;
            if (this.$refs.meetingLaunchForm.resetFields()) {
                this.$refs.meetingLaunchForm.resetFields();
            }
            this.meetingLaunchForm.meetingPeople = "";
            this.meetingLaunchForm.teacherName = "";

            // 发起会议弹窗关闭按钮的时候情况两侧人员选择状态
            // 左侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
        },
        // 发起会议确定
        meetingLaunchConfirm(formName) {
            // meetingType 1发起会议2预约会议
            let appointStartTime = "";
            let appointEndTime = "";
            // console.log(this.meetingLaunchForm.subject)
            // 地址栏获取项目标识蚕食
            let param = param2Obj(location.search);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabledState = true;
                    this._fet("/school/schoolMeetingAnalysis/save", {
                        sponsorId: this.$store.state.teacherId,
                        projectMark: this.projectMark,
                        title: this.meetingLaunchForm.meetingTtitle,
                        typeId: this.meetingLaunchForm.meetingType,
                        subjectId: this.meetingLaunchForm.subject,
                        subject: this.meetingLaunchForm.subjectName,
                        gradeId: this.meetingLaunchForm.grade,
                        grade: this.meetingLaunchForm.gradeName,
                        parentId: this.meetingLaunchForm.parentId,
                        parentName: this.meetingLaunchForm.parentName,
                        openScreencap: this.meetingLaunchForm.screenRecording,
                        joinPeople: this.meetingLaunchForm.meetingParticipant,
                        appointStartTime: "",
                        appointEndTime: "",
                        meetingType: "1",
                        productId: this.meetingLaunchForm.productId,
                        schoolId: this.$store.state.schoolId,
                    }).then((res) => {
                        if (res.data.code === "200") {
                            this.meetingLaunchData.dialogVisible = false;
                            this.$refs.meetingLaunchForm.resetFields();
                            this.meetingLaunchForm.meetingPeople = "";
                            this.meetingLaunchForm.teacherName = "";
                            this.$message.success("发起会议成功");
                            // 左侧选中人员数组集合
                            this.meetingLaunchAddPeopleForm.meetingPeopleList =
                                [];
                            // 右侧选中人员数组集合
                            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList =
                                [];
                            this.disabledState = false;

                            this.meetingOriginatePeopleForm.teacherName = "";
                            this.meetingLaunchAddPeopleForm.teacherName = "";
                            this.getTeacherList();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                            this.disabledState = false;
                        }
                    });
                }
            });
        },
        // 预约会议打开弹窗
        meetingAppointmentClick() {
            this.meetingLaunchAddPeopleData.isGroup = true
            // 获取 当前日期时间
            let currsDetaStr = "";
            let currsDetaSelect = "";
            let currDeta = new Date();
            // 当前年
            let yearsDeta = currDeta.getFullYear();
            // 当前月
            let monthsDeta = currDeta.getMonth() + 1;
            // 当前日
            let daysDeta = currDeta.getDate();
            // 当前时
            let hourDeta = currDeta.getHours();
            // 当前分钟
            let minuteDeta = currDeta.getMinutes();
            // 当前分钟15分钟后
            let minuteSelectBefor = minuteDeta + 15;
            // 当前秒
            let secondsSelect = currDeta.getSeconds();
            // 预约会议时间默认赋值
            monthsDeta = monthsDeta < 10 ? "0" + monthsDeta : monthsDeta;
            daysDeta = daysDeta < 10 ? "0" + daysDeta : daysDeta;
            hourDeta = hourDeta < 10 ? "0" + hourDeta : hourDeta;
            minuteDeta = minuteDeta < 10 ? "0" + minuteDeta : minuteDeta;
            minuteSelectBefor =
                minuteSelectBefor < 10
                    ? "0" + minuteSelectBefor
                    : minuteSelectBefor;
            secondsSelect =
                secondsSelect < 10 ? "0" + secondsSelect : secondsSelect;
            // 获取当前年月日
            currsDetaStr = yearsDeta + "-" + monthsDeta + "-" + daysDeta;
            // 获取当前时分秒(15分钟后)
            currsDetaSelect =
                hourDeta + ":" + minuteSelectBefor + ":" + secondsSelect;

            this.meetingAppointmentForm.meetingDetaTime = currsDetaStr;
            // 赋值时间选择可选范围
            this.meetingStartDate.selectableRange =
                currsDetaSelect + "-23:59:59";
            this.meetingEndDate.selectableRange = currsDetaSelect + "-23:59:59";
            // 处理人员选择与发起人在参会人列表中禁止选中
            let dataList =
                this.meetingOriginatePeopleForm.meetingSelectPeopleList;
            let teacherId = this.$store.state.teacherId;
            this.meetingOriginatePeopleForm.meetingPeopleList = [];
            this.meetingOriginatePeopleForm.meetingSelectedPeopleList = [];
            this.meetingAppointmentForm.meetingPeople = [];
            for (let j = 0; j < dataList.length; j++) {
                if (teacherId === dataList[j].id) {
                    // 参会人选择框左侧选中人员集合
                    this.meetingOriginatePeopleForm.meetingPeopleList.push(
                        dataList[j].id,
                    );
                    // 参会人选择框右侧选中人员集合
                    this.meetingOriginatePeopleForm.meetingSelectedPeopleList.push(
                        dataList[j],
                    );
                    // 发起人选择框右侧选中人员集合
                    this.meetingAppointmentForm.meetingPeople.push(dataList[j]);
                }
            }
            this.meetingAppointmentData.dialogVisible = true;
            // console.log("我已经置空");
            // this.meetingAppointmentForm.meetingPeople = [];
        },
        /**
         * @Description: 预约会议弹窗关闭按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-07 17:13:11
         */
        meetingAppointmentClose() {
            console.log("关闭");
            this.selectObjData = [];
            this.meetingLaunchForm.meetingParticipant = [];
            this.meetingAppointmentForm.meetingParticipant = [];
            this.meetingOriginatePeopleForm.meetingSelectedPeopleList = [];
            this.treeData.parentId = null;
            this.treeData.name = "";
            console.log(this.treeData, "[[[[[[[[this.treeData]]]]]]]]");
            // this.meetingOriginatePeopleForm.meetingSelectPeopleList=[]
            this.meetingAppointmentData.dialogVisible = false;
            if (this.$refs.meetingAppointmentForm.resetFields()) {
                this.$refs.meetingAppointmentForm.resetFields();
            }
            // 预约会议弹窗关闭按钮的时候情况两侧人员选择状态
            // 左侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
        },

        // 预约会议获取科目名称
        getYSubjectName(val) {
            let dataList = this.meetingAppointmentForm.subjectOptions;
            if (val) {
                for (let j = 0; j < dataList.length; j++) {
                    if (val === dataList[j].id) {
                        this.meetingAppointmentForm.subjectName =
                            dataList[j].name;
                    }
                }
            } else {
                this.meetingAppointmentForm.subjectName = "";
            }
        },
        // 预约会议获取年级名称
        getYGradeName(val) {
            let dataList = this.meetingAppointmentForm.gradeOptions;
            if (val) {
                for (let j = 0; j < dataList.length; j++) {
                    if (val === dataList[j].id) {
                        this.meetingAppointmentForm.gradeName =
                            dataList[j].name;
                    }
                }
            } else {
                this.meetingAppointmentForm.gradeName = "";
            }
        },
        /**
         * @Description: 预约会议添加发起人
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-07 17:49:27
         */
        addAppointmentPeopleClick() {
            // 发起人添加时前先将数据限制重置为可选
            this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                (item) => {
                    item.disabled = false;
                },
            );

            // let datasList = this.meetingLaunchAddPeopleForm.meetingPeopleList
            // let dataSeleList = this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList

            // for(let j = 0; j < dataSeleList.length ; j++) {
            // 	for(let k = 0; k < datasList.length; k++) {
            // 		if(datasList[k] !== dataSeleList[j].id) {
            // 			this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.splice(j ,1)
            // 		}
            // 	}
            // }
            // 添加会议发起人限制已经选中的参会人不可选择未发起人
            if (this.meetingAppointmentForm.meetingParticipant.length > 0) {
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                    (item) => {
                        this.meetingAppointmentForm.meetingParticipant.forEach(
                            (sitem) => {
                                if (item.id === sitem.id) {
                                    item.disabled = true;
                                }
                            },
                        );
                    },
                );
            }

            this.meetingOriginatePeopleData.dialogVisible = true;
            console.log(
                this.meetingOriginatePeopleForm.meetingSelectedPeopleList,
                "/////",
            );
        },
        // 预约会议添加发起人关闭弹窗
        meetingOriginatePeopleClose() {
            this.meetingOriginatePeopleForm.teacherName = "";
            this.meetingLaunchAddPeopleForm.teacherName = "";
            this.getTeacherList();
            this.meetingOriginatePeopleData.dialogVisible = false;
        },
        // 预约会议添加发起人获取主键
        meetingOriginatePeopleListChange(val) {
            let dataList =
                this.meetingOriginatePeopleForm.meetingSelectPeopleList;
            this.meetingOriginatePeopleForm.meetingSelectedPeopleList = [];
            // 人员主键相同新增到选中数组中
            for (var j = 0, len = dataList.length; j < len; j++) {
                for (var k = 0, leng = val.length; k < leng; k++) {
                    if (val[k] === dataList[j].id) {
                        this.meetingOriginatePeopleForm.meetingSelectedPeopleList.push(
                            dataList[j],
                        );
                    }
                }
            }
        },
        meetingALSelectedPeopleRemoveClick(param) {
            let dataList = this.meetingOriginatePeopleForm.meetingPeopleList;
            let dataSelectList =
                this.meetingOriginatePeopleForm.meetingSelectedPeopleList;
            this.meetingAppointmentForm.meetingPeople = [];
            for (var k = 0, len = dataList.length; k < len; k++) {
                if (param.id === dataList[k]) {
                    this.meetingOriginatePeopleForm.meetingPeopleList.splice(
                        k,
                        1,
                    );
                }
            }
            for (var j = 0, len = dataSelectList.length; j < len; j++) {
                if (param.id === dataSelectList[j].id) {
                    this.meetingOriginatePeopleForm.meetingSelectedPeopleList.splice(
                        j,
                        1,
                    );
                }
            }
        },
        // 预约会议添加发起人移除
        meetingOriginatePeopleRemoveClick(param) {
            let dataList = this.meetingOriginatePeopleForm.meetingPeopleList;
            let dataSelectList =
                this.meetingOriginatePeopleForm.meetingSelectedPeopleList;
            for (var k = 0, len = dataList.length; k < len; k++) {
                if (param.id === dataList[k]) {
                    this.meetingOriginatePeopleForm.meetingPeopleList.splice(
                        k,
                        1,
                    );
                }
            }
            for (var j = 0, len = dataSelectList.length; j < len; j++) {
                if (param.id === dataSelectList[j].id) {
                    this.meetingOriginatePeopleForm.meetingSelectedPeopleList.splice(
                        j,
                        1,
                    );
                }
            }
        },
        // 预约会议添加发起人取消
        meetingOriginatePeopleCancel() {
            if (this.meetingAppointmentForm.meetingPeople.length === 0) {
                this.meetingOriginatePeopleForm.meetingPeopleList = [];
                this.meetingOriginatePeopleForm.meetingSelectedPeopleList = [];
            }
            this.meetingOriginatePeopleData.dialogVisible = false;
            this.meetingOriginatePeopleForm.teacherName = "";
            this.meetingLaunchAddPeopleForm.teacherName = "";
            this.getTeacherList();
        },
        // 预约会议添加发起人确定
        meetingOriginatePeopleConfirm() {
            this.meetingAppointmentForm.meetingPeople =
                this.meetingOriginatePeopleForm.meetingSelectedPeopleList;
            this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                (item) => {
                    this.meetingAppointmentForm.meetingPeople.forEach(
                        (sitem) => {
                            if (item.id === sitem.id) {
                                item.disabled = true;
                            } else {
                                item.disabled = false;
                            }
                        },
                    );
                },
            );
            this.meetingOriginatePeopleData.dialogVisible = false;
            this.meetingOriginatePeopleForm.teacherName = "";
            this.meetingLaunchAddPeopleForm.teacherName = "";
            console.log(this.meetingAppointmentForm.meetingPeople, "88888");
            this.getTeacherList();
        },
        /**
         * @Description: 预约会议添加参会人
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 10:28:43
         */
        async addPeopleAppointmentClick() {
            if (this.meetingAppointmentForm.meetingPeople.length === 0) {
                this.$message.warning("请先选择会议发起人");
                return false;
            } else {
                // 获取会议发起人的主键，限制选择参会人时不可选择会议发起人
                let teacherId = this.meetingAppointmentForm.meetingPeople[0].id;
                this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.forEach(
                    (item) => {
                        if (item.id === teacherId) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    },
                );
            }
            // this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
            this.dialogType = "appointmentDialog";
            // 已选择参会人员列表集合
            this.treeData.parentId = ""; //回显时 展示所有数据
            await this.getTreeData(); //重置数据后，需要回显的数据也会清空
            this.selectObjData = [
                ...this.meetingAppointmentForm.meetingParticipant,
            ]; //重新赋值
            this.meetingLaunchAddPeopleData.dialogVisible = true;
        },
        // meetingALSelectedPeopleRemoveClick
        /**
         * @Description: 预约会议移除人员信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 10:36:33
         * @param {*} item
         */
        meetingASelectedPeopleRemoveClick(item) {
            // 删除回显的数据
            let index1 = this.selectObjData.findIndex((i) => {
                return i.id == item.id;
            });
            this.selectObjData.splice(index1, 1);
            // 删除展示的数据
            let index2 =
                this.meetingAppointmentForm.meetingParticipant.findIndex(
                    (i) => {
                        return i.id == item.id;
                    },
                );
            this.meetingAppointmentForm.meetingParticipant.splice(index2, 1);
            // 删除 回显的按人员选择的数据
            // let index3=this.meetingLaunchAddPeopleForm.meetingSelectPeopleList.findIndex(i=>{
            //     return i.id==item.id
            // })
            // this.meetingLaunchAddPeopleForm.meetingSelectPeopleList[index3].checked=false
            // // 左侧选中人员数组集合
            // let dataList = this.meetingLaunchAddPeopleForm.meetingPeopleList;
            // // 右侧选中人员数组集合
            // let dataSelectedList = this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList;
            // // 预约会议弹窗选中人员信息数组集合
            // let selectedPeopleList = this.meetingAppointmentForm.meetingParticipant;
            // // 预约会议弹窗选中人员信息移除
            // for(var i = 0, len = selectedPeopleList.length; i < len; i++) {
            // 	if(param.id === selectedPeopleList[i]) {
            // 		this.meetingAppointmentForm.meetingParticipant.splice(i, 1);
            // 	}
            // }
            // // 添加参会人弹窗左侧选中人员信息移除
            // for(var k = 0, len = dataList.length; k < len; k++) {
            // 	if(param.id === dataList[k]) {
            // 		this.meetingLaunchAddPeopleForm.meetingPeopleList.splice(k, 1);
            // 	}
            // }
            // // 添加参会人弹窗右侧选中人员信息移除
            // for(var j = 0, len = dataSelectedList.length; j < len; j++) {
            // 	if(dataSelectedList[j] && dataSelectedList[j].id === param.id) {
            // 		this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList.splice(j, 1);
            // 	}
            // }
        },
        // 预约会议取消
        meetingAppointmentCancel() {
            this.meetingAppointmentData.dialogVisible = false;
            if (this.$refs.meetingAppointmentForm.resetFields()) {
                this.$refs.meetingAppointmentForm.resetFields();
            }
            // 左侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingPeopleList = [];
            // 右侧选中人员数组集合
            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList = [];
        },
        // 获取科目名称
        getAddSubjectName(val) {
            let dataList = this.meetingAppointmentForm.subjectOptions;
            for (let j = 0; j < dataList.length; j++) {
                if (val === dataList[j].id) {
                    this.meetingAppointmentForm.subjectName = dataList[j].name;
                }
            }
        },
        // 获取年级名称
        getAddGradeName(val) {
            let dataList = this.meetingAppointmentForm.gradeOptions;
            for (let j = 0; j < dataList.length; j++) {
                if (val === dataList[j].id) {
                    this.meetingAppointmentForm.gradeName = dataList[j].name;
                }
            }
        },
        // 监听-发起会议-年级变化
        handleLaunchGradeChange(val) {

            if(!val){
                this.meetingLaunchForm.gradeId = "";
                this.meetingLaunchForm.gradeName = "";
                this.meetingLaunchForm.parentId = "";
                this.meetingLaunchForm.parentName = "";
                return
            }

            let nodes = this.$refs.cascaderLaunchGradeRef.getCheckedNodes();

            console.log(nodes);
            this.meetingLaunchForm.gradeId = nodes[0].value.toString();
            this.meetingLaunchForm.gradeName = nodes[0].label.toString();

            if(nodes[0].parent) {
                let parent = nodes[0].parent
                this.meetingLaunchForm.parentId = parent.value.toString();
                this.meetingLaunchForm.parentName = parent.label.toString();;
            } else {
                this.meetingLaunchForm.parentId = "";
                this.meetingLaunchForm.parentName = "";
                return
            }
        },
        // 监听-预约会议-年级变化
        handleAppointmentGradeChange(val) {
            if(!val){
                this.meetingAppointmentForm.gradeId = "";
                this.meetingAppointmentForm.gradeName = "";
                this.meetingAppointmentForm.parentId = "";
                this.meetingAppointmentForm.parentName = "";
                return
            }

            let nodes = this.$refs.cascaderAppointmentGradeRef.getCheckedNodes();

            console.log(nodes);
            this.meetingAppointmentForm.gradeId = nodes[0].value.toString();
            this.meetingAppointmentForm.gradeName = nodes[0].label.toString();

            if(nodes[0].parent) {
                let parent = nodes[0].parent
                this.meetingAppointmentForm.parentId = parent.value.toString();
                this.meetingAppointmentForm.parentName = parent.label.toString();;
            } else {
                this.meetingAppointmentForm.parentId = "";
                this.meetingAppointmentForm.parentName = "";
                return
            }
        },
        // 预约会议确定
        meetingAppointmentConfirm(formName) {
            let sponsorId = "";
            let remindListStr = "";
            // 获取 当前日期时间
            let currsDetaStr = "";
            let currsDetaSelect = "";
            let currDeta = new Date();
            // 当前年
            let yearsDeta = currDeta.getFullYear();
            // 当前月
            let monthsDeta = currDeta.getMonth() + 1;
            // 当前日
            let daysDeta = currDeta.getDate();
            // 当前时
            let hourDeta = currDeta.getHours();
            let hourBeforTime = null;
            // 当前分钟
            let minuteDeta = currDeta.getMinutes();
            // 当前分钟15分钟后
            let minuteSelectBefor = minuteDeta + 15;
            // 当前秒
            let secondsSelect = currDeta.getSeconds();

            if (hourDeta == 23) {
                hourBeforTime = 0;
            } else {
                hourBeforTime = hourDeta + 1;
            }

            // console.log(this.meetingAppointmentForm.remindList)
            // 赋值预约时间
            let getAppointStartTime = "";
            let getAppointEndTime = "";
            getAppointStartTime =
                this.meetingAppointmentForm.meetingDetaTime +
                " " +
                this.meetingAppointmentForm.startTime;
            getAppointEndTime =
                this.meetingAppointmentForm.meetingDetaTime +
                " " +
                this.meetingAppointmentForm.endTime;
            // meetingType 1发起会议2预约会议
            // console.log('开始时间 ', getAppointStartTime)
            // console.log('结束时间 ', getAppointStartTime)
            // 获取地址栏参数的项目标识
            let param = param2Obj(location.search);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 选中预约时间的开始时间
                    // 小时
                    let selectStartHDate = "";
                    // 分钟
                    let selectStartMDate = "";

                    selectStartHDate =
                        this.meetingAppointmentForm.startTime.substring(0, 2);
                    selectStartMDate =
                        this.meetingAppointmentForm.startTime.substring(3, 5);

                    // 当前天的时候才限制小时数
                    if (
                        daysDeta ==
                        this.meetingAppointmentForm.meetingDetaTime.substring(
                            8,
                            10,
                        )
                    ) {
                        // 当前时间的小时
                        if (selectStartHDate == hourDeta) {
                            if (selectStartMDate < minuteDeta + 15) {
                                this.$message.warning(
                                    "所选开始时间必须晚于当前时间15分钟，请重新选择！",
                                );
                                return false;
                            }
                        }
                        // 临近当前时间的下个小时的时候
                        if (selectStartHDate == hourBeforTime) {
                            // 当前时间分钟小于45时
                            if (60 - minuteDeta < 15) {
                                if (60 - minuteDeta + selectStartMDate < 15) {
                                    this.$message({
                                        type: "warning",
                                        message:
                                            "所选开始时间必须晚于当前时间15分钟，请重新选择！",
                                        duration: 3000,
                                    });
                                    return false;
                                }
                            }
                        }
                    }

                    if (this.meetingAppointmentForm.meetingPeople.length > 0) {
                        sponsorId =
                            this.meetingAppointmentForm.meetingPeople[0].id;
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请选择会议发起人",
                        });
                        return false;
                    }
                    if (this.meetingAppointmentForm.remindList.length > 0) {
                        remindListStr =
                            this.meetingAppointmentForm.remindList.join(",");
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请选择提醒时间",
                        });
                        return false;
                    }
                    this.disabledState = true;
                    let cycleEndDate = this.meetingAppointmentForm.repeatCycle == 1||this.meetingAppointmentForm.repeatCycle == 2 ? this.meetingAppointmentForm.cycleEndDate + " 23:59:59" : "";
                    this.createdLoading = true
                    this._fet("/school/schoolMeetingAnalysis/save", {
                        sponsorId: sponsorId,
                        remindTime: remindListStr,
                        projectMark: this.projectMark,
                        title: this.meetingAppointmentForm.meetingTtitle,
                        typeId: this.meetingAppointmentForm.meetingType,
                        subjectId: this.meetingAppointmentForm.subject,
                        subject: this.meetingAppointmentForm.subjectName,
                        gradeId: this.meetingAppointmentForm.grade,
                        grade: this.meetingAppointmentForm.gradeName,
                        repeatCycle: this.meetingAppointmentForm.repeatCycle,
                        cycleEndDate:cycleEndDate,
                        repeatCycleList: this.meetingAppointmentForm.repeatCycleList,
                        parentId: this.meetingAppointmentForm.parentId,
                        parentName: this.meetingAppointmentForm.parentName,
                        openScreencap:
                            this.meetingAppointmentForm.screenRecording,
                        joinPeople:
                            this.meetingAppointmentForm.meetingParticipant,
                        appointStartTime: getAppointStartTime,
                        appointEndTime: getAppointEndTime,
                        meetingType: "2",
                        productId: this.meetingAppointmentForm.productId,
                        schoolId: this.$store.state.schoolId,
                    }).then((res) => {
                        if (res.data.code === "200") {
                            this.meetingAppointmentData.dialogVisible = false;
                            this.$refs.meetingAppointmentForm.resetFields();
                            this.$message.success("预约会议成功");
                            // 左侧选中人员数组集合
                            this.meetingLaunchAddPeopleForm.meetingPeopleList =
                                [];

                            // 右侧选中人员数组集合
                            this.meetingLaunchAddPeopleForm.meetingSelectedPeopleList =
                                [];
                            this.disabledState = false;

                            this.meetingOriginatePeopleForm.teacherName = "";
                            this.meetingLaunchAddPeopleForm.teacherName = "";
                            this.createdLoading = false
                            this.getTeacherList();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                            this.createdLoading = false
                            this.disabledState = false;
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1440px) {
    .meeting-launch {
        background: #e8f9fc;
        margin-left: 20px;
        .meeting_mobile_bg {
            height: 55%;
            background-image: url("../../../assets/images/meeting/gowill_bg0.png");
            background-repeat: no-repeat;
            border-radius: 8px 8px 0px 0px;
            position: relative;
            .meeting_launch_title {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                opacity: 0.8;
                padding: 18px 0 0 20px;
            }
            .meeting-mobile-wrapper {
                margin-top: 42px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .meeting-mobile-btn {
                cursor: pointer;
                width: 145px;
                height: 145px;
                .gowillpng {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
            .meeting-mobile-btn-info {
                cursor: pointer;
                width: 145px;
                height: 145px;
                .gowillpnginfo {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
            .gowilltext {
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                margin-top: 27px;
                margin-left: 5px;
            }
            .gowilltext_info {
                opacity: 0.5;
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                margin-top: 27px;
            }
        }
        .meeting_mobile_tip {
            height: 45%;
            padding: 40px 15px 0 55px;
            position: relative;
            overflow: hidden;
            .tip_txt {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
            }
            .texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
                margin-top: 18px;
                box-sizing: border-box;
                padding-right: 40px;

                .meeting-tips-row {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #808387;
                    line-height: 32px;
                }
            }
            .gowillplane {
                position: absolute;
                right: 30px;
                bottom: 20px;
            }
        }
    }
    .meeting-appointment {
        background: #e5eeff;
        margin-left: 16px;
        margin-right: 20px;
        .meeting_mobile_bg {
            // width: 846px;
            height: 55%;
            background-image: url("../../../assets/images/meeting/before_bg00.png");
            background-repeat: no-repeat;
            border-radius: 8px 8px 0px 0px;
            position: relative;
            .meeting_launch_title {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                opacity: 0.8;
                padding: 18px 0 0 20px;
            }
            .meeting-mobile-wrapper {
                margin-top: 42px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .meeting-mobile-btn {
                cursor: pointer;
                width: 145px;
                height: 145px;
                .gowillpng {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
            .meeting-mobile-btn-info {
                cursor: pointer;
                width: 145px;
                height: 145px;

                .gowillpnginfo {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }
            .gowilltext {
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                margin-top: 27px;
                margin-left: 5px;
            }
            .gowilltext_info {
                opacity: 0.5;
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                margin-top: 27px;
            }
        }
        .meeting_mobile_tip {
            height: 45%;
            padding: 40px 15px 0 55px;
            position: relative;
            overflow: hidden;
            .tip_txt {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
            }
            .texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
                margin-top: 18px;
                padding-right: 40px;
                .meeting-tips-row {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #808387;
                    line-height: 32px;
                }
            }
            .gowilltime {
                position: absolute;
                right: 30px;
                bottom: 50px;
            }
        }
    }
}

@media screen and (max-width: 1600px ){
	.meeting-launch {
		background: #e8f9fc;
		margin-left: 20px;
		.meeting_mobile_bg {
			height: 55%;
			background-image: url("../../../assets/images/meeting/gowill_bg0.png");
			background-repeat: no-repeat;
			border-radius: 8px 8px 0px 0px;
			position: relative;
			.meeting_launch_title {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.8;
				padding: 18px 0 0 20px;
			}
			.meeting-mobile-wrapper {
				margin-top: 5px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.meeting-mobile-btn {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpng {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.meeting-mobile-btn-info {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpnginfo {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.gowilltext {
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
				margin-left: 5px;
			}
			.gowilltext_info {
				opacity: 0.5;
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
			}
		}
		.meeting_mobile_tip {
			height: 45%;
			padding: 20px 15px 0 25px;
			position: relative;
			overflow: hidden;
			.tip_txt {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2b2e33;
			}
			.texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
				margin-top: 18px;
                padding-right: 40px;
				.meeting-tips-row {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808387;
					line-height: 32px;
				}
			}
			.gowillplane {
				position: absolute;
				right: 30px;
				bottom: 20px;
			}
		}
	}
	.meeting-appointment {
		background: #e5eeff;
		margin-left: 16px;
		margin-right: 20px;
		.meeting_mobile_bg {
			// width: 846px;
			height: 55%;
			background-image: url("../../../assets/images/meeting/before_bg00.png");
			background-repeat: no-repeat;
			border-radius: 8px 8px 0px 0px;
			position: relative;
			.meeting_launch_title {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.8;
				padding: 18px 0 0 20px;
			}
			.meeting-mobile-wrapper {
				margin-top: 5px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.meeting-mobile-btn {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpng {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.meeting-mobile-btn-info {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpnginfo {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.gowilltext {
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
				margin-left: 5px;
			}
			.gowilltext_info {
				opacity: 0.5;
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
			}
		}
		.meeting_mobile_tip {
			height: 45%;
			padding: 20px 15px 0 25px;
			position: relative;
			overflow: hidden;
			.tip_txt {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2b2e33;
			}
			.texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
				margin-top: 18px;
                padding-right: 40px;
				.meeting-tips-row {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808387;
					line-height: 32px;
				}
			}
			.gowilltime {
				position: absolute;
				right: 30px;
				bottom: 50px;
			}
		}
	}

}

@media screen and (max-width: 1400px ){
	.meeting-launch {
		background: #e8f9fc;
		margin-left: 20px;
		.meeting_mobile_bg {
			height: 60%;
			background-image: url("../../../assets/images/meeting/gowill_bg0.png");
			background-repeat: no-repeat;
			border-radius: 8px 8px 0px 0px;
			position: relative;
			.meeting_launch_title {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.8;
				padding: 18px 0 0 20px;
			}
			.meeting-mobile-wrapper {
				margin-top: -15px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.meeting-mobile-btn {
				cursor: pointer;
				width: 94px;
				height: 94px;
				.gowillpng {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.meeting-mobile-btn-info {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpnginfo {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.gowilltext {
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
				margin-left: 5px;
			}
			.gowilltext_info {
				opacity: 0.5;
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
			}
		}
		.meeting_mobile_tip {
			height: 40%;
			padding: 10px 10px 0 15px;
			position: relative;
			overflow: hidden;
			.tip_txt {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2b2e33;
			}
			.texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
				margin-top: 5px;
                padding-right: 40px;
				.meeting-tips-row {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808387;
					line-height: 18px;
				}
			}
			.gowillplane {
				position: absolute;
				right: -40px;
				bottom: 26px;
			}
		}
	}
	.meeting-appointment {
		background: #e5eeff;
		margin-left: 16px;
		margin-right: 20px;
		.meeting_mobile_bg {
			// width: 846px;
			height: 60%;
			background-image: url("../../../assets/images/meeting/before_bg00.png");
			background-repeat: no-repeat;
			border-radius: 8px 8px 0px 0px;
			position: relative;
			.meeting_launch_title {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				opacity: 0.8;
				padding: 18px 0 0 20px;
			}
			.meeting-mobile-wrapper {
				margin-top: -15px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.meeting-mobile-btn {
				cursor: pointer;
				width: 94px;
				height: 94px;
				.gowillpng {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.meeting-mobile-btn-info {
				cursor: pointer;
				width: 114px;
				height: 114px;
				.gowillpnginfo {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
			.gowilltext {
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
				margin-left: 5px;
			}
			.gowilltext_info {
				opacity: 0.5;
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 18px;
			}
		}
		.meeting_mobile_tip {
			height: 40%;
			padding: 10px 10px 0 15px;
			position: relative;
			overflow: hidden;
			.tip_txt {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2b2e33;
			}
			.texts {
                position: absolute;//为了让z-index生效
                z-index: 10;
				margin-top: 5px;
                padding-right: 40px;
				.meeting-tips-row {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #808387;
					line-height: 18px;
				}
			}
			.gowilltime {
				position: absolute;
				right: 30px;
				bottom: 50px;
			}
		}
	}

}

.el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
.spanTip {
    // margin: 0px 100px 5px 100px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    .tipImg{
        width: 14px;
        height: 14px;
        margin-right: 3px
    }
}
/* 顶部提示 */
.meetings-page {
    background-color: #fff;
    .meeting_top {
        margin-bottom: 15px;
        padding: 15px 0 0 20px;
        display: flex;
        overflow: hidden;
        .meeting_all {
            cursor: pointer;
            min-width: 225px;
            width: 264px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            border-radius: 8px;
            background-image: url("../../../assets/images/meeting/all_meeting_bg.png");
            .meeting_img {
                width: 40px;
                height: 40px;
                margin-left: 12px;
                margin-right: 18px;
            }
            .meeting_title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
                margin-right: 18px;
            }
            .meeting_num {
                font-size: 32px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2b2e33;
            }
        }
        .meeting_will {
            cursor: pointer;
            min-width: 225px;
            width: 264px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            border-radius: 8px;
            background-image: url("../../../assets/images/meeting/my_meeting_bg2.png");
            .meeting_img {
                width: 40px;
                height: 40px;
                margin-left: 12px;
                margin-right: 18px;
            }
            .meeting_title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
                margin-right: 28px;
            }
            .meeting_num {
                font-size: 32px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f2aa18;
            }
        }
        .meeting_done {
            cursor: pointer;
            min-width: 225px;
            width: 264px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            border-radius: 8px;
            background-image: url("../../../assets/images/meeting/my_meeting_bg2.png");
            .meeting_img {
                width: 40px;
                height: 40px;
                margin-left: 12px;
                margin-right: 18px;
            }
            .meeting_title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
                margin-right: 18px;
            }
            .meeting_num {
                font-size: 32px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2b2e33;
            }
        }
        .meeting_mine {
            cursor: pointer;
            min-width: 225px;
            width: 264px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            border-radius: 8px;
            background-image: url("../../../assets/images/meeting/my_meeting_bg2.png");
            .meeting_img {
                width: 40px;
                height: 40px;
                margin-left: 12px;
                margin-right: 18px;
            }
            .meeting_title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2e33;
                margin-right: 18px;
            }
            .meeting_num {
                font-size: 32px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2b2e33;
            }
        }
        .meeting_info {
            width: 360px;
            height: 64px;
            background: #ffffff;
            border: 1px dashed #e1e3e6;
            border-radius: 8px;
            display: flex;
            cursor: pointer;
            margin-right: 10px;

            .meeting_img {
                width: 14px;
                height: 14px;
                margin-right: 7px;
                margin-top: 14px;
                margin-left: 10px;
            }
            .meeting_title {
                margin: 10px 0;
                margin-right: 5px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808387;
                flex-wrap: wrap;
                display: -webkit-box; //对象作为弹性伸缩盒子模型显示
                -webkit-box-orient: vertical; //设置或检索伸缩盒对象的子元素的排列方式
                -webkit-line-clamp: 2; //溢出省略的界限
                overflow: hidden;
            }
        }
    }

    .meeting-wrap-content {
        display: flex;
        height: calc(100vh - 270px);
        .meeting-cell {
            flex: 1;
            height: calc(100vh - 280px);
            border-radius: 8px;
        }
    }

    /* 发起/预约会议 */
    .meeting-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 72px auto 0;
        background: url(../../../assets/images/meetingOnlineBg.png) no-repeat;
        background-size: 62%;
        background-position: 0 84%;
        padding-bottom: 70px;
        box-sizing: border-box;
        .meeting-cell {
            width: 48%;
            padding: 28px;
            border-radius: 16px;
            background-color: rgba(255, 255, 255, 0.6);
        }
        .meeting-launch {
            margin-left: 10px;
            margin-right: 20px;
        }
        .meeting-appointment {
            margin-left: 20px;
            margin-right: 10px;
        }
    }
}

/* 功能标题 */
.meetings-page .meeting-mobile-title {
    display: flex;
    justify-content: space-between;
}

.meetings-page .meeting-mobile-title .meeting-mobile-title-txt {
    color: #676d73;
    font-size: 20px;
    font-weight: 600;
}

/* 功能图标 */
.meetings-page .meeting-mobile-icon {
    width: 144px;
    height: 144px;
    margin: 123px auto 0;
    overflow: hidden;
    border-radius: 50%;
}

.meeting-mobile-icon img {
    width: 100%;
    height: 100%;
}

/* 功能提示 */
.meetings-page .meeting-mobile-tips {
    width: 100%;
    padding-top: 75px;
    padding-bottom: 89px;
    padding-left: 0;
}

.meetings-page .meeting-mobile-tips-row {
    color: #676d73;
    font-size: 16px;
    text-align: center;
    padding: 4px 0;
}

/* 功能按钮 */
.meetings-page .meeting-mobile-button {
    text-align: center;
    margin-bottom: 80px;
}

.meetings-page .meeting-mobile-button .el-button--primary {
    font-size: 18px;
    padding: 17px 81px;
    border-radius: 6px;
}

/* 弹窗 */
.meetings-dialogs-people .el-form {
    padding-left: 0;
}
.meetings-dialogs-people .el-form .el-form-item__content {
    margin-left: 0 !important;
}

.meetings-dialogs .el-form-item__error {
    left: 45px;
}

.meetings-dialogs .el-form .el-select .el-input {
    margin-left: 45px !important;
}

.meetings-dialogs .el-form .el-form-item__label {
    width: 100px !important;
    /* text-align: left; */
}

.meetings-dialogs .el-dialog {
    border-radius: 10px;
}

.meetings-dialogs .el-dialog__body {
    padding: 30px 0 17px;
    padding-right: 10px;
    overflow: auto;
}

.meetings-dialogs .el-form .el-select {
    display: block;
}

.meetings-dialogs .el-form .el-date-editor {
    width: 100%;
}

.meetings-dialogs .el-dialog__header {
    height: 40px;
    /* line-height: 40px; */
    text-align: left;
    background: #ededed;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
}

.meetings-dialogs .el-dialog__header .el-dialog__title {
    color: #2b2f33;
}

.meetings-dialogs .flex_end {
    display: flex;
    justify-content: flex-end;
}
.meetings-dialogs .el-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 预约时间样式 */
.meeting_select-bxo {
    display: flex;
    .meeting-time .el-date-editor {
        width: 138px;
    }
    .meeting-b-date {
        /deep/ .el-form-item__content {
            margin-left: 0px !important;
        }
        .br-line {
            width: 1px;
            height: 20px;
            margin: 6px 6px 0;
            background-color: #e1e3e6;
        }
    }
    .meeting-s-date {
        /deep/ .el-form-item__content {
            margin-left: 0px !important;
        }
        .el-date-editor {
            width: 138px;
        }
    }
    .meeting-m-date {
        /deep/ .el-form-item__content {
            margin-left: 0px !important;
        }
        margin: 0 6px;
    }
    .meeting-e-date {
        /deep/ .el-form-item__content {
            margin-left: 0px !important;
        }
        .el-date-editor {
            width: 138px;
        }
    }
}
/* 发起人 */
.meetings-dialogs .meeting-appointment-participant {
    display: flex;
    width: 452px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    padding: 3px 8px;
}

.meetings-dialogs .meeting-appointment-participant:hover {
    border: 1px solid #b8b8b8;
}

.meetings-dialogs .meeting-appointment-participant .meeting-participant-cell {
    display: flex;
    width: 120px;
    height: 32px;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.meetings-dialogs
    .meeting-appointment-participant
    .meeting-participant-add_button {
    color: #3c7fff;
    line-height: 32px;
    cursor: pointer;
}

.meetings-dialogs .meeting-appointment-participant .el-icon-plus {
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 4px;
}

.meetings-dialogs .meeting-appointment-participant .meeting-participant-people {
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 9px;
}

/* 参会人 */
.meetings-dialogs .meeting-participant {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 452px;
    height: 100px;
    overflow-y: auto;
    padding: 4px 0;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
}

.meetings-dialogs .meeting-participant:hover {
    border: 1px solid #b8b8b8;
}

.meetings-dialogs .meeting-participant-people-close {
    cursor: pointer;
}

.meetings-dialogs .meeting-participant .meeting-participant-cell {
    display: flex;
    width: 110px;
    height: 32px;
    margin: 4px 0;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.meetings-dialogs .meeting-participant .meeting-participant-add_button {
    width: 123px;
    color: #3c7fff;
    line-height: 32px;
    cursor: pointer;
    margin-left: 10px;
}

.meetings-dialogs .meeting-participant .el-icon-plus {
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 4px;
}

/* 添加参会人 */
.meetings-dialogs .meeting-participant .meeting-participant-people {
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 9px;
}

/* 添加参会人头像 */
.meetings-dialogs .meeting-participant-people-icon {
    width: 26px;
    height: 26px;
    margin-top: 3px;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #3c7fff;
}

.meetings-dialogs .meeting-participant-people-icon-text {
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 26px;
}

.meetings-dialogs .meeting-participant-people-wrap {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.meetings-dialogs .meeting-participant-people-name {
    width: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 参会人姓名 */
.meetings-dialogs-people .meeting-participant-people-name {
    color: #595959;
    font-size: 12px;
}

/* 添加参会人弹窗 */
.meetings-dialogs-people .el-dialog__body {
    padding: 0;
}

.meetings-dialogs-people .meetings-dialogs-add_people {
    display: flex;
    justify-content: space-between;
    height: 520px;
}
.meetings-dialogs-people
    .meetings-dialogs-add_people
    .meetingOriginatePeopleRef_search
    .el-input__inner {
    padding-left: 31px;
}
.meetings-dialogs-people .meetings-dialogs-add_people .add_people-cell {
    width: 50%;
    padding-top: 23px;
    padding-left: 24px;
    box-sizing: border-box;
}

.meetings-dialogs-people .meetings-dialogs-add_people .add_people-list {
    border-left: 1px solid #ebeef5;
}

.meetings-dialogs-people .checkbox-people {
    display: flex;
    justify-content: space-between;
}

/* 用户头像 */
.meetings-dialogs-people .checkbox-people .checkbox-people-icon {
    width: 36px;
    height: 36px;
    background-color: #3c7fff;
    border-radius: 4px;
    overflow: hidden;
}

.meetings-dialogs-people .checkbox-people .checkbox-people-icon img {
    width: 100%;
    height: 100%;
}

.meetings-dialogs-people
    .checkbox-people
    .checkbox-people-icon
    .checkbox-people-icon-text {
    height: 36px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 36px;
}

/* 用户姓名 */
.meetings-dialogs-people .checkbox-people .checkbox-people-name {
    min-width: 42px;
    height: 36px;
    color: #595959;
    font-size: 14px;
    line-height: 36px;
    margin-left: 10px;
}
.meetings-dialogs-people .el-checkbox {
    display: block;
    margin-top: 15px;
}

/* 已选择人员 */
.meetings-dialogs-people .add_people-list-num {
    display: flex;
    height: 16px;
    color: #595959;
    font-size: 14px;
    line-height: 16px;
}

/* 已选择人员列表 */
.meetings-dialogs-people .add_people-list-info {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    padding-top: 26px;
    height: 435px;
}

.add_people-list-info-wrap {
    display: flex;
    width: 120px;
    height: 32px;
    margin: 4px 0;
    border-radius: 4px;
    background-color: #f5f5f5;
}

/* 已选择人员列表头像 */
.meetings-dialogs-people .add_people-list-info-avatar {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    margin-top: 3px;
    margin-left: 4px;
    overflow: hidden;
    background-color: #3c7fff;
}

.meetings-dialogs-people .add_people-list-info-avatar img {
    width: 100%;
    height: 100%;
}

.meetings-dialogs-people
    .add_people-list-info-avatar
    .add_people-list-info-avatar-text {
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 26px;
}

.meetings-dialogs-people .add_people-list-info-namer {
    display: flex;

    justify-content: space-between;
    width: 72%;
}

.meetings-dialogs-people .add_people-list-real_name {
    height: 32px;
    color: #595959;
    font-size: 12px;
    line-height: 32px;
    margin-left: 10px;
}

.meetings-dialogs-people .add_people-list-remove_button {
    padding-top: 6px;
    cursor: pointer;
}

.meetings-dialogs-people .add_people-list-button {
    display: flex;
    // flex-direction: row;
    justify-content: flex-end;
}

/* 预约会议提醒时间 */
.remind-time .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
}
::v-deep .remind-time{
    .el-form-item__error{
        margin-top: -6px !important;
    }
}
.remind-time .el-checkbox.is-bordered.el-checkbox--small {
    width: 107px;
    margin-right: 8px;
    margin-top: 4px;
}

.remind-time .el-checkbox__input {
    height: 100%;
    position: relative;
}

.remind-time .el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner {
    position: absolute;
    width: 16px;
    left: -10px;
    bottom: -4px;
    border: 0;
}

.remind-time .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: transparent;
    background: url(../../../assets/images/meeting_check_boxs_icon.png)
        no-repeat;
    background-size: 100%;
    background-position: center;
    left: -11px !important;
    bottom: -5px !important;
}

.remind-time .el-checkbox-group {
    max-width: 460px;
}

.remind-time
    .el-checkbox.is-bordered.el-checkbox--small
    .el-checkbox__inner::after {
    display: none;
}

.metting-select .el-select-dropdown {
    min-width: 528px !important;
}
.repeat-box{
    display: flex;
    align-items: center;
    .repeat{
        display: flex;
        align-items: center;
        margin-right: 48px;
        cursor: pointer;
        position: relative;
        .jian{
            position: absolute;
            top: 20px;
            left: 2px;
        }
        .icon{
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid #BBBDBF;
            color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            margin-right: 8px;
        }
        .iconOn{
            border: 1px solid transparent;
            color: #3C7FFF;

        }
        .label{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #2B2E33;
        }
        .labelOn{
            color: #3C7FFF;
        }
    }
}
.week-box{
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 9px 7px;
    border-radius: 4px;
    .week{
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        font-size: 12px;
        color: #595959;

    }
    .weekOn{
        background-color: #3c7fff;
        color: #fff;
    }
}
</style>
